import React, { useImperativeHandle, useRef } from "react";
import { DragSource, DropTarget } from "react-dnd";
import ItemTypes from "./ItemTypes";
import ThreeDot from "../../assets/images/three-dot.png";
import { Card, Spinner, Dropdown } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { useMediaQuery } from "@material-ui/core";

// eslint-disable-next-line react/display-name
const CardDrag = React.forwardRef(
  (
    {
      isDragging,
      connectDragSource,
      connectDropTarget,
      key,
      index,
      id,
      item,
      moveCard,
      tempLink,
      tempLinkDisable,
      tempLinkUrl,
      tempCategory,
      isDeleting,
      deleteLinkId,
      setDeleteLinkId,
      submitLinkData,
      updateLinkChange,
      handleShow,
    },
    ref
  ) => {
    const isMobile = useMediaQuery("(max-width: 430px)");

    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));

    const getSelectedType = (item) => {
      if (item.link_type) {
        return item.link_type;
      } else {
        return "Category";
      }
    };

    return (
      <div ref={elementRef}>
        <Card className="linksCardStyle pt-2">
          <div className="d-flex align-items-center">
            <img src={ThreeDot} className="dotsStyle" />
            <div class="verticalLine"></div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between w-100 px-3">
                <input
                  name="title"
                  type="text"
                  placeholder="title"
                  className="inputFieldStyleLink mb-2 px-2"
                  width="100%"
                  id={
                    tempLink != null && tempLink.id == item.id
                      ? tempLink.id
                      : item.id
                  }
                  value={
                    tempLink != null && tempLink.id == item.id
                      ? tempLink.value
                      : item.title
                  }
                  onChange={updateLinkChange}
                  onBlur={(e) => {
                    submitLinkData(item.id);
                  }}
                />
                <div className="">
                  <label class="switch">
                    <input
                      name="disable"
                      type="checkbox"
                      checked={
                        tempLinkDisable != null && tempLinkDisable.id == item.id
                          ? tempLinkDisable.checked
                          : item.disable
                      }
                      onChange={updateLinkChange}
                      id={
                        tempLinkDisable != null && tempLinkDisable.id == item.id
                          ? tempLinkDisable.id
                          : item.id
                      }
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="d-flex px-3">
                <input
                  name="url"
                  type="text"
                  id={
                    tempLinkUrl != null && tempLinkUrl.id == item.id
                      ? tempLinkUrl.id
                      : item.id
                  }
                  placeholder="Url"
                  className="inputFieldStyleLink mb-2 py-1 px-2"
                  value={
                    tempLinkUrl != null && tempLinkUrl.id == item.id
                      ? tempLinkUrl.value
                      : item.url
                  }
                  onChange={updateLinkChange}
                  onBlur={(e) => {
                    submitLinkData(item.id);
                  }}
                />
              </div>

              <div
                className={
                  !isMobile
                    ? "d-flex justify-conteny-between  w-100 row px-3"
                    : "justify-conteny-between  w-100 row px-3"
                }
              >
                <div
                  className={
                    !isMobile
                      ? "d-flex justify-content-start align-items-center col-4"
                      : "d-flex justify-content-start align-items-center col-12"
                  }
                >
                  <Dropdown
                    onSelect={(eventKey, event) => updateLinkChange(event)}
                  >
                    <Dropdown.Toggle
                      className="dropdownStyle"
                      id="dropdown-basic"
                      name="link_type"
                    >
                      {getSelectedType(item)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ height: 250, overflowY: "scroll" }}>
                      <Dropdown.Item
                        name="Website"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Website
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Newsletter"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Newsletter
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Contest"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Contest
                      </Dropdown.Item>

                      <Dropdown.Item
                        name="Jobs"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Jobs
                      </Dropdown.Item>

                      <Dropdown.Item
                        name="Merchandise"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Merchandise
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Article/Blog Post"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Article/Blog Post
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Wallet"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Wallet
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="iOS App"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        iOS App
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Android"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Android App
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Podcast"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Podcast
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Workshop"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Workshop
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Webinar"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Webinar
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Whitepaper"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Whitepaper
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Partnership"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Partnership
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Other"
                        id={
                          tempCategory != null && tempCategory.id == item.id
                            ? tempCategory.id
                            : item.id
                        }
                      >
                        Other
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div
                  className={
                    !isMobile
                      ? "d-flex justify-content-end align-items-center col-8"
                      : "ml-3 d-flex justify-content-between align-items-center"
                  }
                >
                  <div
                    className={
                      !isMobile
                        ? "d-flex align-items-center"
                        : "d-flex align-items-center pt-2"
                    }
                  >
                    <div>
                      <h6 className={isMobile ? "clicksStyleLink" : ""}>
                        Total Clicks : {item.total_clicks} &nbsp;
                      </h6>
                    </div>
                    <div>
                      <h6 className={isMobile ? "clicksStyleLink" : ""}>
                        Unique Clicks : {item.unqiue_clicks}
                      </h6>
                    </div>
                  </div>

                  <div className={!isMobile ? "pl-3" : ""}>
                    {isDeleting && deleteLinkId === item.id ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <BsTrash
                        onClick={() => {
                          handleShow();
                          setDeleteLinkId(item.id);
                        }}
                        className={!isMobile ? "trashColor" : "trashColor ml-5"}
                        style={{
                          fontSize: "24px",
                          marginTop: "15px",
                          marginBottom: "15px",
                          color: "000000 !important",
                          backgroundColor: "000000 !important",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
);
export default DropTarget(
  ItemTypes.CARD,
  {
    // eslint-disable-next-line no-restricted-syntax
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(CardDrag)
);
