import React from "react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getDataWithAuth } from "../../utilis/api";
import profileImg from "../../assets/images/profileImg.png";
import fb from "../../assets/images/fb.png";
import youtube from "../../assets/images/youtube.png";
import twitter from "../../assets/images/twitter.png";
import telegram from "../../assets/images/telegram.png";
import Reddit from "../../assets/images/Rebbit.png";
import Medium from "../../assets/images/Medium.png";
import linkdin from "../../assets/images/linkdin.png";
import instagram from "../../assets/images/instagram.png";
import Discord from "../../assets/images/Discord.png";
import Github from "../../assets/images/github.png";
import "./SocialLinksMobileViewStyle.css";
import Logo from "../../assets/images/Logo.png";


const SocialLinksMobileView = (props) => {
  const [userName, setUserName] = useState();
  const [isLoadingAllLinks, setIsLoadingAllLinks] = useState(false);
  const [userId, setUserId] = useState();
  const [links, setLinks] = useState([]);
  const [change, setChange] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const userData = async () => {
    const response = await getDataWithAuth("/api/v1/users/me");
    if (!response.success) {
      return;
    }
    setUserName(response.data.data.user.username);
    setUserId(response.data.data.user.id);

  };
  const showAllLinks = async () => {
    setIsLoadingAllLinks(true);

    const response = await getDataWithAuth(
      `/api/v1/users/${userId}/default_links`
    );

    if (!response.success) {
      setIsLoadingAllLinks(false);
      return;
    }
    setIsLoadingAllLinks(false);
    setLinks(response.data.data.data);
  };
  useEffect(() => {
    userData();
  }, []);
  useEffect(() => {
    showAllLinks();
  }, [props.change]);
  return (
    <>
      <div className="socialMobileContainer">
        <div className="marginRtStyle">
          <div class="text-center">
            <img src={profileImg} class="rounded imgStylee" alt="..." />
          </div>
          <div className="d-flex justify-content-center align-items-center pt-3">
            <p className="usernameStyle">{userName}</p>
          </div>
          <div className="">
            {links
              .filter((item) => item.disable)
              .map((item) => (
                <div  className="d-flex justify-content-center pt-2">
                  <div className="linksStyles col-6 p-1">
                    <div className="row d-flex justify-content-center align-items-center">
                      {item.title == "Medium " ? (
                        <a href={item.url}>
                          <img src={Medium} className="socialIconStyle " />
                        </a>
                      ) : item.title == "Reddit" ? (
                        <a href={item.url}>
                          <img src={Reddit} className="socialIconStyle" />
                        </a>
                      ) : item.title == "Facebook" ? (
                        <a href={item.url}>
                          <img src={fb} className="socialIconStyle" />
                        </a>
                      ) : item.title == "GitHub" ? (
                        <a href={item.url}>
                          <img src={Github} className="socialIconStyle" />
                        </a>
                      ) : item.title == "Discord" ? (
                        <a href={item.url}>
                          <img src={Discord} className="socialIconStyle" />
                        </a>
                      ) : item.title == "Instagram Handle" ? (
                        <a href={item.url}>
                          <img src={instagram} className="socialIconStyle" />
                        </a>
                      ) : item.title == "LinkedIn" ? (
                        <a href={item.url}>
                          <img src={linkdin} className="socialIconStyle" />
                        </a>
                      ) : item.title == "Youtube" ? (
                        <a href={item.url}>
                          <img src={youtube} className="socialIconStyle" />
                        </a>
                      ) : item.title == "Twitter Handle" ? (
                        <a href={item.url}>
                          <img src={twitter} className="socialIconStyle" />
                        </a>
                      ) : item.title == "Telegram" ? (
                        <a href={item.url}>
                          <img src={telegram} className="socialIconStyle" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                 </div>
              ))}
          </div>
          <img src={Logo}  alt='image'  />

        </div>
      </div>
    </>
  );
};
export default SocialLinksMobileView;
