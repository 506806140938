import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { getIsPaid, getToken } from "../utilis/LocalStorage";
import { useLocation} from "react-router";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()

  const checkToken = () => {
    const token = getToken();
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsPaid = () => {
    const isPaid = getIsPaid();
    return isPaid
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        checkToken() && !checkIsPaid() && location.pathname == "/links" ? (
          <Redirect to="/personalinfo" />
        ) : checkToken() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
