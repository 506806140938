import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken , getIsPaid } from "../utilis/LocalStorage";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const checkToken = () => {
    const token = getToken();
    if (token) {
      return true;
    } else {
      return false;
    }
  };

const checkIsPaid = () => {
  const isPaid = getIsPaid();
  return isPaid
};

  return (
    <Route
      {...rest}
      render={(props) =>
        checkToken() && checkIsPaid() && restricted ? (
          <Redirect to="/links" />
        ) :  checkToken() && !checkIsPaid() && restricted ? (
          <Redirect to="/personalinfo" />
        ) :(
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
