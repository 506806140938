import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { validateEmail, validatePassword } from "../../utilis/Helper";
import { setToken, getToken, setIsPaid } from "../../utilis/LocalStorage";
import { getDataWithAuth, postData } from "../../utilis/api";
import { useHistory } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";
import Logo from "../../assets/images/CryptoLogo.svg";
import Copyright from "../../assets/images/Copyrights.svg";
import images from "../../assets/images/img.svg";
import { useSnackbar } from "notistack";
import LinkButton from "../LinkButton";
import { BiArrowBack } from "react-icons/bi";
import "./LoginStyle.css";
import { useMediaQuery } from "@material-ui/core"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

const Login = () => {
    let history = useHistory();
    const isMobile = useMediaQuery("(max-width: 430px)");
    const [data, setData] = useState({ email: "", password: "" });
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setisLoading] = useState(false);
    // const [pwd, setPwd] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);

    const onChangeHandle = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    const postDataCall = async (event) => {
        event.preventDefault();
        setisLoading(true);
        const response = await postData("/api/v1/users/login", data);
        if (!response.success) {
            setisLoading(false);
            enqueueSnackbar(response.error.message, {
                variant: "error",
            });
            return;
        }
        setToken(response.data.data.access_token);
        const token = getToken();
        if (token) {
            setisLoading(false);
            userData();
        }
    };

    const userData = async () => {
        const response = await getDataWithAuth("/api/v1/users/me");

        if (response.data.data.user.is_paid) {
            setIsPaid(response.data.data.user.is_paid);
            history.push("./links");
        } else {
            if (!response.data.data.user.persnol_detail) {
                history.push("./personalinfo");
            } else {
                history.push("./features");
            }
        }
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Go Back
        </Tooltip>
    );

    const SignUpPage = () => {
        history.push("/signup");
    };

    return (
        <>
            <div className="componentHeight">
                <div className="row mx-0 my-0">
                    <div
                        className={`col-lg-4 col-md-5  col-sm-12  col-xs-12  d-flex justify-content-center ${!isMobile ? "mainBackground":""}`}
                    >
                        <div className="row d-flex align-items-center justify-content-center ">
                            <div>
                                <img
                                    src={Logo}
                                    className={!isMobile ? "pb-5" :"pt-3"}
                                    style={{ objectFit: "contain" }}
                                />
                            </div>
                            {!isMobile ?
                            <> 
                            <div>
                                <img
                                    src={images}
                                    className="signupStyle "
                                    style={{ height: "60vh" }}
                                />
                            </div>
                            <div>
                            <p className="pt-3" style={{color:"#246bd6"}}>Copyright © 2022 CryptoLinx Ltd. All rights reserved.</p>
                            </div>
                            </>
                            :""
                            }
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-7 col-xs-12 col-sm-12 scrollClass container ">
                        <div className="row float-right">
                            <Button
                                className="signupButtonStyles font-weight-bold"
                                onClick={SignUpPage}
                            >
                                Sign Up
                            </Button>
                        </div>
                    <div className=" float-left" onClick={() => history.push("/")}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <BiArrowBack
                                    style={{
                                        color: "#246BD6",
                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                        height: "40px",
                                        width: "40px",
                                        padding: "5px",
                                        margin: "10px",
                                        borderRadius: "15px",
                                    }}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className=" d-flex flex-column justify-content-center align-items-center signInCointanerStyle row mt-2 marginTopStyle w-100 ">
                            <h6 className="signIn ">Sign In</h6>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <div className="w-100 ">
                                    <Form className="w-100">
                                        <div className={`signInCointanerStyle  ${!isMobile ? "formPadding":""}`}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="labelStyle mt-4">
                                                    Email
                                                </Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter your email"
                                                    required
                                                    isInvalid={
                                                        data.email.length >= 1 && !validateEmail(data.email)
                                                    }
                                                    isValid={
                                                        data.email.length > 1 && validateEmail(data.email)
                                                    }
                                                    className={!isMobile ? "w-75 inputFieldStyle": "inputFieldStyleMobile"}
                                                    name="email"
                                                    onChange={onChangeHandle}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    isInvalid={
                                                        data.email.length > 1 && !validateEmail(data.email)
                                                    }
                                                    className="mx-3 "
                                                >
                                                    Please enter a valid email address.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <div className="">
                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label className="labelStyle mt-3">
                                                        Password
                                                    </Form.Label>
                                                    <Form.Control
                                                        type={isRevealPwd ? "text" : "password"}
                                                        placeholder="Enter your password"
                                                        className={!isMobile ? "w-75 inputFieldStyle": "inputFieldStyleMobile"}
                                                        name="password"
                                                        required
                                                        onChange={onChangeHandle}
                                                        isValid={
                                                            data.password.length >= 8 &&
                                                            validatePassword(data.password)
                                                        }
                                                    />
                                                    {  !isRevealPwd ? 
                                                        <BsFillEyeFill
                                                            title={
                                                                isRevealPwd ? "Hide password" : "Show password"
                                                            }
                                                            onClick={() => setIsRevealPwd(!isRevealPwd)}
                                                            className={isMobile ? "eyeIconStyleMobile":"eyeIconStyle"}
                                                        /> :
                                                        <BsFillEyeSlashFill
                                                            title={
                                                                isRevealPwd ? "Hide password" : "Show password"
                                                            }
                                                            onClick={() => setIsRevealPwd(!isRevealPwd)}
                                                            className={isMobile ? "eyeIconStyleMobile":"eyeIconStyle"}
                                                        />

                                                    }
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        isInvalid={
                                                            data.password.length > 1 &&
                                                            !validatePassword(data.password)
                                                        }
                                                        className="mx-3 "
                                                    >
                                                        Invalid Password
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="mt-4">
                                                <a
                                                    href="./forgetPassword"
                                                    className="forgotPasswordStyle pl-4 "
                                                >
                                                    Forgot password?
                                                </a>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center  LoginButtonStyle ">
                                            <LinkButton
                                                variant="primary"
                                                type="submit"
                                                onClick={postDataCall}
                                                disabled={
                                                    data.email.length < 1 || data.password.length < 1
                                                }
                                                className="submitButtonSignup mt-3 mb-5 "
                                                showSpinner={isLoading}
                                                label="Sign In"
                                            />
                                        </div>
                                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;
