import SimpleCrypto from "simple-crypto-js";
const secretKey = "some-unique-key";
const simpleCrypto = new SimpleCrypto(secretKey);

export const setToken = (token) => {
  const cipherText = simpleCrypto.encrypt(token);
  localStorage.setItem("token", cipherText);
};

export const getToken = () => {
  var data = localStorage.getItem("token");
  if (data != null) {
    const decipherText = simpleCrypto.decrypt(data);
    return decipherText;
  } else {
    return false;
  }
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const setIsPaid = (flag) => {
  var string_flag = flag.toString()
  localStorage.setItem("is_paid", string_flag );
};

export const getIsPaid = () => {
  var data = localStorage.getItem("is_paid");
  if (data != null) {
    return true;
  } else {
    return false;
  }
};

export const removeIsPaid = () => {
  localStorage.removeItem("is_paid");
};
