import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import {
  Login,
  Signup,
  BillingDetails,
  ThankyouPage,
  Navbar,
  AddLinks,
  Links,
  Home,
  Dashboard,
  Features,
  Subscribe,
  PersonalInfo,
  ForgetPassword,
  ResetPassword,
  InternalNavbar,
  SocialLinks,
  ShowAllLinks,
  EditProfile,
  SocialLinksMobileView,
  Analytics,
} from "./component";
import CookiePolicy from "./component/CookiePolicy";
import Terms from "./component/Terms"
import PrivacyPolicy from "./component/PrivacyPolicy"
import PrivateRoute from "./routes/PrivateRouting";
import PublicRoute from "./routes/PublicRouting";
import DragDrop from "./component/DragDrop"

ReactDOM.render(
  <>
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Router>
        <Switch>
          <PrivateRoute component={AddLinks} path="/addlinks" exact />
          <PrivateRoute component={DragDrop} path="/links" exact />
          <PrivateRoute component={EditProfile} path="/editProfile" exact />
          <PrivateRoute
            component={BillingDetails}
            path="/billingDetails"
            exact
          />
          <PrivateRoute component={SocialLinks} path="/socialLinks" exact />
          <PrivateRoute component={Analytics} path="/analytics" exact />
          
          <PrivateRoute component={PersonalInfo} path="/personalinfo" exact />
          <PrivateRoute component={ThankyouPage} path="/thankyou" exact />
          <PrivateRoute component={Features} path="/features" exact />
          <PrivateRoute component={Subscribe} path="/subscribe" exact />
          <PublicRoute
            component={ForgetPassword}
            path="/forgetPassword"
            exact
            restricted={true}
          />
          <PublicRoute
            component={ResetPassword}
            path="/reset_password"
            exact
            restricted={true}
          />
          <PublicRoute
            component={CookiePolicy}
            path="/cookiepolicy"
            exact
            restricted={true}
          />
          <PublicRoute
            component={Terms}
            path="/terms"
            exact
            restricted={true}
          />
          <PublicRoute
            component={PrivacyPolicy}
            path="/privacypolicy"
            exact
            restricted={true}
          />
          <PublicRoute component={Navbar} path="/navbar" exact />
          <PublicRoute
            component={InternalNavbar}
            path="/internalNavbar"
            exact
          />

          <PublicRoute component={Home} path="/" restricted={true} exact />
          {/* <PublicRoute
            component={SocialLinksMobileView}
            path="/S"
            restricted={true}
            exact
          /> */}
          <Route component={SocialLinksMobileView} path="/S"></Route>

          <PublicRoute
            restricted={true}
            component={Login}
            path="/login"
            exact
          />
          <PublicRoute
            restricted={true}
            component={Signup}
            path="/signup"
            exact
          />
          <PublicRoute
            restricted={false}
            component={ShowAllLinks}
            path="/:profileLink"
            exact
          />
        </Switch>
      </Router>
    </SnackbarProvider>
  </>,
  document.getElementById("root")
);
