import React from "react";
import { loadStripe } from '@stripe/stripe-js';
import "./BillingDetailsStyle.css";
import { Elements } from '@stripe/react-stripe-js';
import MyCheckoutForm from "./MyCheckoutForm";

const BillingDetails = () => {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  return (
    <>
      <Elements stripe={stripePromise}>
        <MyCheckoutForm />
      </Elements>
    </>

  );
}
export default BillingDetails;
