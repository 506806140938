import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { validateEmail } from "../../utilis/Helper";
import { postData } from "../../utilis/api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Spinner } from "react-bootstrap";
import LinkButton from "../LinkButton";
import Logo from "../../assets/images/CryptoLogo.svg";
import Copyright from "../../assets/images/Copyrights.svg";
import images from "../../assets/images/img.svg";
import { useMediaQuery } from "@material-ui/core"

import image from "../../assets/images/links-img.png";
import "./ForgetPasswordStyle.css";

const ForgetPassword = () => {
    let history = useHistory();

    const [data, setData] = useState({ email: "" });
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setisLoading] = useState(false);
    const isMobile = useMediaQuery("(max-width: 430px)");
    const onChangeHandle = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const postDataCall = async (event) => {
        event.preventDefault();
        setisLoading(true);

        const response = await postData("api/v1/users/forget_password", data);

        if (!response.success) {
            setisLoading(false);
            enqueueSnackbar(response.error.message, {
                variant: "error",
            });

            return;
        }

        enqueueSnackbar("Email has been sent", {
            variant: "info",
        });

        setisLoading(false);
    };
    const signUp = () => {
        history.push("./signup");
    };
    const login = () => {
        history.push("./login");
    };

    return (
        <>
            <div className="componentHeight ">
                <div className="row mx-0 my-0">
                    <div
                        className={`col col-md-5 col-sm-12 col-lg-4 col-xs-12 d-flex justify-content-center ${!isMobile ? "mainBackground" : ""}`}
                    >
                        <div className="row d-flex align-items-center justify-content-center ">
                            <div>
                                <img src={Logo} className={!isMobile ? "pb-3" : "pt-3"} />
                            </div>
                            {!isMobile ?
                                <>
                                    <div>
                                        <img
                                            src={images}
                                            className="signupStyle "
                                            style={{ height: "85vh" }}
                                        />
                                    </div>
                                    <div>
                                        <img src={Copyright} className="pb-5" />
                                    </div>
                                </> : ""
                            }
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-7 col-xs-12 col-sm-12 container scrollClass">
                        {/* <Form className={!isMobile ? "d-flex navMrarginRight float-right mx-5 mt-3" : "d-flex navMrarginRight float-right mx-5 mt-3"}> */}
                        <div className={!isMobile ?"d-flex justify-content-center navMrarginRight float-right mx-5 mt-3": "d-flex float-left mx-3 mt-3"}>
                            <Button className="ButtonColr mx-5" onClick={signUp}>
                                Sign Up
                            </Button>
                            <Button className="navButtonColr" onClick={login}>
                                Sign in
                            </Button>
                        {/* </Form> */}
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center mt-5 w-100 forgetmarginTopStyle">
                            <h6 className="forgetHeadingStyle ">Forgot Password? </h6>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <div className="w-100 ">
                                    <Form>
                                        <div className={!isMobile ? "forgetCointanerStyle formPadding ":"forgetCointanerStyle"}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="labelStyle">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter your email"
                                                    required
                                                    isInvalid={
                                                        data.email.length >= 1 && !validateEmail(data.email)
                                                    }
                                                    isValid={
                                                        data.email.length > 1 && validateEmail(data.email)
                                                    }
                                                    className={!isMobile ? "w-75 inputFieldStyle pb-2 px-3" : "w-100 inputFieldStyleMobile pb-2"}
                                                    name="email"
                                                    onChange={onChangeHandle}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    isInvalid={
                                                        data.email.length > 1 && !validateEmail(data.email)
                                                    }
                                                    className="mx-3 "
                                                >
                                                    Please enter a valid email address.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center mt-5 mr-3 ">
                                            <LinkButton
                                                variant="primary"
                                                type="submit"
                                                onClick={postDataCall}
                                                disabled={data.email.length < 1}
                                                className={!isMobile ? "submitButtonStyle mx-5 pt-3 pb-4" : "submitButtonStyle pt-3 pb-4"}
                                                showSpinner={isLoading}
                                                label="Send Link"
                                            />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ForgetPassword;
