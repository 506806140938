import React from "react";
import "./LinkButtonStyle.css";
import { Spinner } from "react-bootstrap";

const LinkButton = (props) => {
  return (
    <>
      {props.showSpinner ? (
        <div className="ms-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <button
          varient={props.varient}
          type={props.type}
          onClick={props.onClick}
          className={props.className}
          disabled={props.disabled}
          style={{
            opacity: props.disabled ? 0.5 : "",
          }}
        >
          {props.label}
        </button>
      )}
    </>
  );
};
export default LinkButton;
