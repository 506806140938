import React, { useState, useEffect } from "react";
import { Form, Button, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { BsFillEyeFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import LinkButton from "../LinkButton";
import { validateEmail, validatePassword } from "../../utilis/Helper";
import { postData, getData } from "../../utilis/api";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import images from "../../assets/images/SignUpImage.svg";
import Logo from "../../assets/images/CryptoLogo.svg";
import ProgressBar from "../../assets/images/P1.svg";
import Copyright from "../../assets/images/Copyrights.svg";
import { setToken, getToken } from "../../utilis/LocalStorage";
import { BiArrowBack } from "react-icons/bi";
import "./SignupStyle.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import { useMediaQuery } from "@material-ui/core"
import {INITIALS} from "../../utilis/constants"

const Signup = () => {
    // const initials = "Cryptolinx.io/";
    const [data, setData] = useState({
        username: `${INITIALS}`,
        email: "",
        password: "",
        confirm_password: null,
    });
    let history = useHistory();
    const isMobile = useMediaQuery("(max-width: 430px)");
    const [recaptchaValue, setRecaptchaValue] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [checkBoxValue, setCheckBoxValue] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealConPwd, setIsRevealConPwd] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };
    const handleClosePrivacyPolicy = () => {
        setShowPrivacyPolicy(false);
    };
    const handleShowPrivacyPolicy = () => {
        setShowPrivacyPolicy(true);
    };

    const onChangeHandle = (e) => {
        const { name, value } = e.target;
        if(name==="username"){
                const input = value;
                const slicedInput = input.slice(0, 14);
                if (slicedInput === INITIALS) {
                  if (value.length < 14) {
                    setData({ ...data, [name]: INITIALS });
                  } else {
                    setData({ ...data, [name]: value });
                  }
                }
         } else {
            setData({ ...data, [name]: value });
        }
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Go Back
        </Tooltip>
    );
    const handlePostCall = async (event) => {
        event.preventDefault();
        setisLoading(true);
        const response = await postData("/api/v1/users", {...data, username : data.username.substring(14)});
        if (!response.success) {
            setisLoading(false);
            enqueueSnackbar(response.error.message, {
                variant: "error",
            });
            return;
        }
        enqueueSnackbar("Sign Up Successful", {
            variant: "info",
        });
        setisLoading(false);
        setToken(response.data.data.token);
        history.push("./personalinfo");

    };
    const onChange = (value) => {
        setRecaptchaValue(false);
    };
    const Signinpage = () => {
        history.push("./login");
    };
    const onChangeHandleCheck = (e) => {
        if (e.target.checked == true) {
            setCheckBoxValue(true);
        } else {
            setCheckBoxValue(false);
        }
    };

    const name = new URLSearchParams(useLocation().search).get("username");

    useEffect(() => {
        setData({ ...data, username: name });
    }, [name]);

    useEffect(() => { }, [setCheckBoxValue]);

    useEffect(async () => {
        const response = await getData(
            `/api/v1/users/username?username=${data.username}`
        );
        if (!response.success) {
            setIsError(true);
            setErrorMessage(response.error.message);
            return;
        }
        setIsError(false);
    }, [data.username]);

    return (
        <>
            <div className="componentHeightSignup">
                <div className="row mx-0 my-0">
                    <div
                        className={`col col-md-5 col-sm-12 col-lg-4 col-xs-12 d-flex justify-content-center ${!isMobile ? "mainBackground":""}`}
                    >
                        <div className="row d-flex align-items-center justify-content-center ">
                            <div className={!isMobile ? "pb-5" :"pt-3" }>
                                <img src={Logo} style={{ objectFit: "contain" }} />
                            </div>
                            {!isMobile ?
                            <>
                            <div>
                                <img
                                    src={images}
                                    className="signupStyle"
                                    style={{ height: "60vh", objectFit: "contain" }}
                                />
                            </div>
                            <div>
                            <p className="pt-3" style={{color:"#246bd6"}}>Copyright © 2022 CryptoLinx Ltd. All rights reserved.</p>
                            </div>
                            </> :""
}
                        </div>
                    </div>

                    <div
                        className=" col-md-6 col-lg-7 col-xs-12 col-sm-12  scrollClass  container "
                        style={{ height: "100vh ", overflow: "scroll" }}
                    >
                        <div className=" float-right ">
                            <Button
                                className="signinButton font-weight-bold "
                                onClick={Signinpage}
                            >
                                Sign in
                            </Button>
                        </div>
                        <div className=" float-left" onClick={() => history.push("/")}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <BiArrowBack
                                    style={{
                                        color: "#246BD6",
                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                        height: "40px",
                                        width: "40px",
                                        padding: "5px",
                                        margin: "10px",
                                        borderRadius: "15px",
                                    }}
                                />
                            </OverlayTrigger>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center align-items-center flex-column w-100 marginTopStyles">
                            <img src={ProgressBar}  height="53px" width="100%" />
                                <div className=" d-flex flex-column justify-content-center align-items-center signInCointaner">
                                    <h6 className="signUp">Sign Up</h6>
                                </div>
                                <div className={!isMobile ? "w-100 formPadding":"w-100"}>
                                    <Form className="d-flex  flex-column ">
                                        <Form.Row>
                                            <Col>
                                                <Form.Label className=" mx-2 labelStyleSignup">
                                                    User Name
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder={`${INITIALS}`}
                                                    name="username"
                                                    value={data.username}
                                                    required
                                                    isInvalid={isError}
                                                    onChange={onChangeHandle}
                                                    className={!isMobile ? "inputFieldSignup w-75" :"inputFieldSignupMobile w-100" }
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    isInvalid={isError}
                                                    className="mx-3 "
                                                >
                                                    {errorMessage}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Row>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="mt-2 mx-2 labelStyleSignup">
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                required
                                                isInvalid={
                                                    data.email.length >= 1 && !validateEmail(data.email)
                                                }
                                                isValid={
                                                    data.email.length > 1 && validateEmail(data.email)
                                                }
                                                className={!isMobile ? "inputFieldSignup w-75" :"inputFieldSignupMobile w-100" }
                                                name="email"
                                                onChange={onChangeHandle}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid"
                                                isInvalid={
                                                    data.email.length > 1 && !validateEmail(data.email)
                                                }
                                                className="mx-3 "
                                            >
                                                Please enter a valid email address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="">
                                            <Form.Group controlId="formBasicPassword " hasValidation>
                                                <Form.Label className="mx-2 labelStyleSignup">
                                                    Password
                                                </Form.Label>
                                                <Form.Control
                                                    type={isRevealPwd ? "text" : "password"}
                                                    placeholder="Enter password"
                                                    required
                                                    className={!isMobile ? "inputFieldSignup w-75" :"inputFieldSignupMobile w-100" }
                                                    name="password"
                                                    aria-describedby="passwordHelpBlock"
                                                    isInvalid={
                                                        data.password.length > 1 &&
                                                        !validatePassword(data.password)
                                                    }
                                                    isValid={
                                                        data.password.length >= 8 &&
                                                        validatePassword(data.password)
                                                    }
                                                    onChange={onChangeHandle}
                                                />
                                                {
                                                    <BsFillEyeFill
                                                        title={
                                                            isRevealPwd ? "Hide password" : "Show password"
                                                        }
                                                        onClick={() => setIsRevealPwd(!isRevealPwd)}
                                                        className={isMobile ? "eyeIconStyleSignUp":"eyeIconStyleSign"}
                                                    />
                                                }
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    isInvalid={
                                                        data.password.length > 1 &&
                                                        !validatePassword(data.password)
                                                    }
                                                    className="mx-3 "
                                                >
                                                    Your password must be 8-20 characters long, containing at least 1 capital letter and 1 number.

                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicPassword ">
                                                <Form.Label className="mx-2 labelStyleSignup">
                                                    Confirm Password
                                                </Form.Label>
                                                <div>
                                                    <Form.Control
                                                        type={isRevealConPwd ? "text" : "password"}
                                                        placeholder="Confirm password"
                                                        required
                                                        className={!isMobile ? "inputFieldSignup w-75" :"inputFieldSignupMobile w-100" }
                                                        name="confirm_password"
                                                        isInvalid={
                                                            data.confirm_password &&
                                                            ((data.confirm_password.length >= 1 &&
                                                                !validatePassword(data.confirm_password)) ||
                                                                data.confirm_password !== data.password)
                                                        }
                                                        isValid={
                                                            data.confirm_password &&
                                                            data.confirm_password.length >= 8 &&
                                                            data.confirm_password === data.password
                                                        }
                                                        onChange={onChangeHandle}
                                                    />
                                                    {
                                                        <BsFillEyeFill
                                                            title={
                                                                isRevealConPwd
                                                                    ? "Hide password"
                                                                    : "Show password"
                                                            }
                                                            onClick={() => setIsRevealConPwd(!isRevealConPwd)}
                                                            className={isMobile ? "eyeIconStyleSignUp":"eyeIconStyleSign"}
                                                        />
                                                    }

                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        isInvalid={
                                                            data.confirm_password &&
                                                            data.confirm_password.length > 1 &&
                                                            !validatePassword(data.confirm_password) &&
                                                            data.confirm_password.length !==
                                                            data.password.length
                                                        }
                                                        className="mx-3"
                                                    >
                                                        Password and Confirm Password does not match.
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className={!isMobile ? "mt-1  d-flex align-items-center justify-content-center recapchaStyle":"d-flex align-items-center justify-content-center ml-5"}>
                                            <ReCAPTCHA
                                                sitekey="6LfOYBwcAAAAAIN9Sam69Lnt6BGyr_pRQ45HNXHm"
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className={"mt-2 d-flex justify-content-start"}>
                                            <input
                                                id="checkbox"
                                                type="checkbox"
                                                name="check"
                                                onChange={onChangeHandleCheck}
                                                className="checkboxStyle"
                                            />
                                            <label
                                                for="checkbox"

                                                className= {isMobile ? "termStyleSignupMobile" :"termStyleSignup"}
                                            >
                                                &nbsp; By creating an account you are agreeing to our{" "}
                                                <>
                                                    <a className="c " href="/terms" target="_blank">
                                                        Terms of Service
                                                    </a>
                                                    <Modal show={show} onHide={handleClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Term of Service</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            Woohoo, you're reading this text in a modal!
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => {
                                                                    handleClose();
                                                                }}
                                                            >
                                                                Agree
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </>
                                                {} and {}
                                                <>
                                                    <a className="c " href="/privacypolicy" target="_blank">
                                                        Privacy Policy
                                                    </a>
                                                    <Modal
                                                        show={showPrivacyPolicy}
                                                        onHide={handleClosePrivacyPolicy}
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Privacy Policy</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            Woohoo, you're reading this text in a modal!
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => {
                                                                    handleClosePrivacyPolicy();
                                                                }}
                                                            >
                                                                Agree
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </>
                                                .
                                            </label>
                                        </div>
                                        <div className={`d-flex justify-content-center align-items-center mt-2 ${!isMobile ?"w-75":"w-100"}`}>
                                            <LinkButton
                                                className=" submitButtonSignup mt-1 mb-1 "
                                                variant="primary"
                                                type="submit"
                                                onClick={(e) => {
                                                    handlePostCall(e) 
                                                }
                                                }
                                                disabled={
                                                    data.password.length < 1 ||
                                                    (data.confirm_password &&
                                                        data.confirm_password.length < 1) ||
                                                    data.email.length < 1 ||
                                                    data.username.length < 1 ||
                                                    !checkBoxValue
                                                }
                                                showSpinner={isLoading}
                                                label="Sign Up"
                                            />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Signup;