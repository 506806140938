import React from "react";
import Logo from "../../assets/images/Logo.png";
import {
  Nav,
  Container,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import "./NavbarStyle.css";
import { useHistory } from "react-router-dom";

const Navbarr = () => {
  const history = useHistory();
  const signIn = () => {
    history.push("./login");
  };
  const signUp = () => {
    history.push("./signup");
  };
  return (
    <>
      <Navbar className="NavColr p-2 rounded" expand="lg" fixed="top">
      <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Brand href="#" className="logo mx-5 navMarginLeft">
          <img
            height="50px"
            width="200px"
            src={Logo}
            style={{ objectFit: "contain" }}
          ></img>
        </Navbar.Brand>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          <Form className="d-flex navMrarginRight">
            <Button className="ButtonColr mx-5" onClick={signIn}>
              Sign in
            </Button>
            <Button className="navButtonColr navButtonColr" onClick={signUp}>
              Sign up
            </Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navbarr;
