import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Links from '../Links/Links'

export default function DragDrop() {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Links />
      </DndProvider>
    </>
  )
}
