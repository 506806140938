import React, { useState, useEffect } from "react";
import images from "../../assets/images/mobileSideImages.svg";
import { useSnackbar } from "notistack";
import checbox from "../../assets/images/featureCheckbox.png";
import MasterCard from "../../assets/images/master_card_image.svg";
import DinersClubCard from "../../assets/images/1.svg";
import AmexCard from "../../assets/images/2.svg";
import UnionPayCard from "../../assets/images/3.svg";
import DiscoverCard from "../../assets/images/4.svg";
import JcbCard from "../../assets/images/5.svg";
import VisaCard from "../../assets/images/6.svg";

import { postDataWithAuth, getDataWithAuth } from "../../utilis/api";
import { Card, Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/images/CryptoLogo.svg";
import ProgressBar from "../../assets/images/P5.svg"
import Copyright from "../../assets/images/Copyrights.svg";
import LinkButton from "../LinkButton";
import "./SubscribeStyle.css";
import { useMediaQuery } from "@material-ui/core"
import {  setIsPaid } from "../../utilis/LocalStorage";


const Subscribe = () => {
    let history = useHistory();
    const location = useLocation();
    console.log("location =====>", location.state)
    const isMobile = useMediaQuery("(max-width: 430px)");
    const { enqueueSnackbar } = useSnackbar();
    const [subscription, setSubscription] = useState();
    const [price_id, setPriceId] = useState();
    const [checkBoxValue, setCheckBoxValue] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const createSubscription = async () => {
        setIsLoading(true);
        const response = await postDataWithAuth(
            `/api/v1/payments/create_subscription`,
            { price_id: price_id }
        );
        if (!response.success) {
            enqueueSnackbar("Couldn't Subscribed ", {
                variant: "error",
            });
            setIsLoading(false);
            return;
        }
        setIsPaid(true);

        enqueueSnackbar("Subscribed successfully", {
            variant: "info",
        });
        setIsLoading(false);
        history.push("/thankyou");
    };

    const userData = async () => {
        const response = await getDataWithAuth("/api/v1/users/me");
        console.log("res", response);

        setSubscription(response.data.data.user.subscription_plan);
    };
    const onChangeHandleCheck = (e) => {
        if (e.target.checked == true) {
            setCheckBoxValue(true);
        } else {
            setCheckBoxValue(false);
        }
    };
    const setPriceValue = () => {
        if (subscription == "influencer") {
            return "$9";
        } else if (subscription == "company") {
            return "$19";
        } else if (subscription == "exchange") {
            return "$29";
        }
    };

    const setTimeValue = () => {
        if (subscription == "influencer") {
            return "per month";
        } else if (subscription == "company") {
            return "per month";
        } else if (subscription == "exchange") {
            return "per month";
        }
    };
    const setPriceid = () => {
        if (subscription == "influencer") {
            setPriceId(process.env.REACT_APP_INFLUENCER_PRICE_ID);
            return price_id;
        } else if (subscription == "company") {
            setPriceId(process.env.REACT_APP_COMPANY_PRICE_ID);
            return price_id;
        } else if (subscription == "exchange") {
            setPriceId(process.env.REACT_APP_EXCHANGE_PRICE_ID);
            return price_id;
        }
    };
    useEffect(() => {
        userData();
        setPriceValue();
    }, []);

    useEffect(() => {
        setPriceid();
    }, [subscription]);

    return (
        <>
            <div className="row mx-0 my-0">
                <div
                    className={`col-lg-4 col-md-4  col-sm-12  col-xs-12  d-flex justify-content-center ${!isMobile ? "mainBackground" :""}`}
                >
                    <div className="row d-flex flex-column scrollClass  align-items-center justify-content-center ">
                        <div>
                            <img
                                src={Logo}
                                className={!isMobile ? "pb-3 mb-5":"pt-3"}
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                        {!isMobile ? 
                        <>
                        <div>
                            <img
                                src={images}
                                className="signupStyle "
                                style={{ height: "60vh" }}
                            />
                        </div>
                        <div className="pt-3 mt-5 ">
                            <p className="pt-3" style={{color:"#246bd6"}}>Copyright © 2022 CryptoLinx Ltd. All rights reserved.</p>
                        </div>
                        </>
                        :""
                        }
                    </div>
                </div>
                <div className="col-md-8  col-sm-12  d-flex justify-content-center align-items-center mt-5  scrollClass">
                    <div className={isMobile ? "w-100":""}>
                        <div className="d-flex justify-content-center">
                    <img src={ProgressBar} height="60px" width="100%" className="pb-3"/>
                    </div>
                        <p className="subscrbeStyle"> Subscribe</p>
                        <div className="d-flex flex-column justify-content-center w-100">
                            <p className="paymentStyle">Payment Method</p>
                            <div className="w-100">
                                <div className="d-flex justify-content-between w-100">
                                    <div className="mr-3">
                                    <img
                                        src={
                                            location.state.cardBrand ==
                                            "amex"
                                                ? AmexCard
                                                : location.state
                                                        .cardBrand ==
                                                    "diners_club"
                                                ? DinersClubCard
                                                : location.state
                                                        .cardBrand ==
                                                    "discover"
                                                ? DiscoverCard
                                                : location.state
                                                        .cardBrand == "jcb"
                                                ? JcbCard
                                                : location.state
                                                        .cardBrand ==
                                                    "mastercard"
                                                ? MasterCard
                                                : location.state
                                                        .cardBrand == "visa"
                                                ? VisaCard
                                                : location.state
                                                        .cardBrand ==
                                                    "unionpay"
                                                ? UnionPayCard
                                                : ""
                                        }
                                            className=""
                                            width="100px"
                                            height="60px"
                                        />
                                    </div>
                                    <div className="w-100">
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            required
                                            readOnly
                                            className="cardNumberStyle"
                                            defaultValue={`**** **** **** ${location.state.cardNumber}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center w-100">
                            <Card className="featureCardStyle w-100 p-2">
                                {!isMobile ?
                                <div className="d-flex justify-content-around">
                                    <div className="boxStyle boxSize  w-10 mt-1">
                                        <p className="price priceStyle">{setPriceValue()}<span>/mo</span> </p>
                                    </div>
                                    <div className="pl-4 w-100">
                                        <p className="companiesStyle companyStyle">{subscription}</p>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 subscribeFont">Guaranteed Username</div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img src={checbox} width={18} height={18}/>
                                                    <div className="pl-1 subscribeFont">Connect Social Networks</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 subscribeFont">Link Analytics</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 subscribeFont">Social Icon</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 subscribeFont">QR Code</div>
                                                </div>


                                            </div>
                                            <div className="col-6">

                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 subscribeFont">Update Instantly</div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img
                                                        src={checbox}
                                                        width={18}
                                                        height={18}
                                                    />
                                                    <div className="pl-1 subscribeFont">
                                                        Unlimited ‘Call to Action’ Links
                                                    </div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img
                                                        src={checbox}
                                                        width={18}
                                                        height={18}
                                                    />
                                                    <div className="pl-1 subscribeFont">Professional Page</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 subscribeFont">Simple Dashboard</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 subscribeFont">Flexible Plans</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                  <div>
                                      <div className="pl-4 w-100">
                                       <p className="companiesStyle companyStyle">{subscription ? subscription.charAt(0).toUpperCase() + subscription.slice(1) :""}</p>
                                       <div className="internalBox">
                                    <div className="boxSubscribeMobile boxSize  w-100 mt-1">
                                        <p className="price priceStyleSubscribe">{setPriceValue()}<span>/mo</span> </p>
                                    </div>
                                    {/* <div className=" w-100"> */}
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1">Guaranteed Username</div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img src={checbox} width={18} height={18} className="mt-1"/>
                                                    <div className="pl-1">Connect Social Networks</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1">Link Analytics</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1">CryptoLinx Social Icon</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1">QR Code</div>
                                                </div>

                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1">Update Links Instantly</div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img
                                                        src={checbox}
                                                        width={18}
                                                        height={18}
                                                        className="mt-1"
                                                    />
                                                    <div className="pl-1">
                                                        Unlimited ‘Call to Action’ Links
                                                    </div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img
                                                        src={checbox}
                                                        width={18}
                                                        height={18}
                                                        className="mt-1"
                                                    />
                                                    <div className="pl-2">Remove CryptoLinx Branding</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1">Simple Dashboard</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1">Flexible Plans</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                 </div>   
                                }
                            </Card>
                            <div className="mt-3 d-flex w-100">
                                <input
                                    id="checkbox"
                                    type="checkbox"
                                    name="check"
                                    className="mt-2"
                                    onChange={onChangeHandleCheck}

                                />
                                <label for="checkbox" className="termsStyle pl-2 pt-2">
                                    By creating an account you are agreeing to our{" "}
                                    <a href="#" className="c underlineText" target="_blank">
                                        Terms of Service
                                    </a>{" "}
                                    and <a className="underlineText" href="#" target="_blank">Privacy Policy</a>
                                    .
                                </label>
                            </div>
                            <div className="w-100 mt-5 mb-1 d-flex justify-content-center">
                                <LinkButton
                                    className="submitButtonSignup"
                                    variant="primary"
                                    type="submit"
                                    onClick={createSubscription}
                                    showSpinner={isLoading}
                                    label="Subscribe"
                                    disabled = {!checkBoxValue}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Subscribe;
