import React, { useState, useEffect } from "react";
import checbox from "../../assets/images/featureCheckbox.png";
import Logo from "../../assets/images/CryptoLogo.svg";
import Copyright from "../../assets/images/Copyrights.svg";
import images from "../../assets/images/img5.svg";
import { getDataWithAuth } from "../../utilis/api";
import { useHistory } from "react-router-dom";
import FeatureProgress from "../../assets/images/P3.svg"
import { Card, Button } from "react-bootstrap";
import "./FeatureStyle.css";
import { useMediaQuery } from "@material-ui/core"


const Features = () => {
    const [subscription, setSubscription] = useState("");
    let history = useHistory();
    const isMobile = useMediaQuery("(max-width: 430px)");
    const userData = async () => {
        const response = await getDataWithAuth("/api/v1/users/me");
        console.log("res", response);
        setSubscription(response.data.data.user.subscription_plan);
    };
    const setPriceValue = () => {
        if (subscription == "influencer") {
            return "$9";
        } else if (subscription == "company") {
            return "$19";
        } else if (subscription == "exchange") {
            return "$29";
        }
    };
    const setTimeValue = () => {
        if (subscription == "influencer") {
            return "per month";
        } else if (subscription == "company") {
            return "per month";
        } else if (subscription == "exchange") {
            return "per month";
        }
    };
    const submitFeature = () => {
        history.push("./billingDetails");
    };
    useEffect(() => {
        userData();
    }, []);
    return (
        <>
            <div className="row mx-0 my-0">
                <div
                    className={`col-md-4  col-sm-12  col-xs-12  d-flex justify-content-center ${!isMobile ? "mainBackground":""}`}
                >
                    <div className="row d-flex align-items-center justify-content-center ">
                        <div>
                            <img src={Logo} className="pt-5" />
                        </div>
                        { !isMobile ? 
                        <>
                        <div>
                            <img
                                src={images}
                                className="signupStyle "
                                style={{ height: "60vh" }}
                            />
                        </div>
                        <div>
                        <p className="pt-3" style={{color:"#246bd6"}}>Copyright © 2022 CryptoLinx Ltd. All rights reserved.</p>
                        </div>
                        </> :
                     ""
                     }
                    </div>
                  
                </div>
                <div className="col-md-8 col-sm-12 d-flex justify-content-center align-items-center scrollClass mt-5">
                    <div className={isMobile ? "w-100":""}>
                        <div className="d-flex justify-content-center pb-2">
                    <img src={FeatureProgress} height="45px" width="100%"/>
                    </div>
                        <p className="featuresStyle"> Features</p>
                        <p className="createAccountStyle">
                        Your monthly subscription includes all the features below.
                        </p>
                        <div className="d-flex flex-column justify-content-center align-items-center w-100">
                            <Card className="featureCardStyle w-100 p-2">
                                {!isMobile ? 
                                <div className="d-flex justify-content-around">
                                    <div className="boxStyle boxSize  w-10 mt-1">
                                        <p className="price priceStyle">{setPriceValue()}<span>/mo</span> </p>
                                    </div>
                                    <div className="pl-4 w-100">
                                        <p className="companiesStyle companyStyle">{subscription ? subscription.charAt(0).toUpperCase() + subscription.slice(1) :""}</p>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">Guaranteed Username</div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">Connect Social Networks</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">CryptoLinx Social Icon</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">Link Analytics</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">QR Code</div>
                                                </div>


                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">Update Links Instantly</div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img
                                                        src={checbox}
                                                        width={18}
                                                        height={18}
                                                    />
                                                    <div className="pl-1 featuresFont">
                                                        Unlimited ‘Call to Action’ Links
                                                    </div>
                                                </div>
                                                <div className="d-flex  pt-2">
                                                    <img
                                                        src={checbox}
                                                        width={18}
                                                        height={18}
                                                    />
                                                    <div className="pl-1 featuresFont">Remove CryptoLinx Branding</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">Simple Dashboard</div>
                                                </div>
                                                <div className="d-flex align-items-center pt-2">
                                                    <img src={checbox} width={18} height={18} />
                                                    <div className="pl-1 featuresFont">Flexible Plans</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                <div className="pl-4 w-100">
                                    <p className="companyStyle">{subscription ? subscription.charAt(0).toUpperCase() + subscription.slice(1) : ""}</p>
                                    <div className="internalBox">
                                    <div className="boxStyleMobile boxSize  w-100 mt-1">
                                    <p className="price priceStyle">{setPriceValue()}<span>/mo</span> </p>
                                </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex align-items-center pt-2">
                                                <img src={checbox} width={18} height={18} />
                                                <div className="pl-1">Guaranteed Username</div>
                                            </div>
                                            <div className="d-flex  pt-2">
                                                <img src={checbox} width={18} height={18} className="mt-1" />
                                                <div className="pl-1">Connect Social Networks</div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2">
                                                <img src={checbox} width={18} height={18} />
                                                <div className="pl-1">Social Icon</div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2">
                                                <img src={checbox} width={18} height={18} />
                                                <div className="pl-1">Link Analytics</div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2">
                                                <img src={checbox} width={18} height={18} />
                                                <div className="pl-1">QR Code</div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2">
                                                <img src={checbox} width={18} height={18} />
                                                <div className="pl-1">Update Instantly</div>
                                            </div>
                                            <div className="d-flex  pt-2">
                                                <img
                                                    src={checbox}
                                                    width={18}
                                                    height={18}
                                                    className="mt-1"
                                                />
                                                <div className="pl-1">
                                                    Unlimited ‘Call to Action’ Links
                                                </div>
                                            </div>
                                            <div className="d-flex  pt-2">
                                                <img
                                                    src={checbox}
                                                    width={18}
                                                    height={18}
                                                    className="mt-1"
                                                />
                                                <div className="pl-1">Professional Page</div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2">
                                                <img src={checbox} width={18} height={18} />
                                                <div className="pl-1">Simple Dashboard</div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2">
                                                <img src={checbox} width={18} height={18} />
                                                <div className="pl-1">Flexible Plans</div>
                                            </div>

                                        </div>
                                    </div>
                                    </div>
                                </div>
                             </div>
                                }
                            </Card>
                            <div className="width65 mt-5">
                                <Button
                                    className={!isMobile ? "mt-5 continueButton" :"continueButton"}
                                    variant="primary"
                                    type="submit"
                                    onClick={submitFeature}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Features;
