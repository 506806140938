import { React, useEffect, useState } from "react";
import {
    Nav,
    Container,
    Navbar,
    NavDropdown,
    Form,
    FormControl,
    Button,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import { getDataWithAuth } from "../../utilis/api";
import { removeToken  , removeIsPaid} from "../../utilis/LocalStorage";
import "./InternalNavbarStyle.css";

const InternalNavbar = () => {
    const [name, setName] = useState();
    let history = useHistory();
    const location = useLocation();

    const onChangeHandle = async () => {
        const response = await getDataWithAuth("/api/v1/users/me");
        if (!response.success) {
            return;
        }

        setName(response.data.data.user.username);
    };
    const logout = async () => {
        await removeToken();
        await removeIsPaid()
        history.push("/login");
    };
    const editProfile = () => {
        history.push("/editProfile");
    };
    useEffect(() => {
        onChangeHandle();
    }, []);

    const checkSelectedItem = path => {
        if (location.pathname === path) return "linkStyle mx-3 current"
        return "socialLinkStyle mx-3 current"
    }

    return (
        <div className="shadow">
            <Navbar fixed="top" className="mr-5 navbarBgColor" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Brand href="#" className="logo marginLftStyle ">
                    <img src={Logo} height="30px"
                        width="170px" style={{ objectFit: "contain" }}></img>
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav">

                    <Nav className="me-auto">
                        <Nav.Link
                            onClick={() => history.push("/links")}
                            className={checkSelectedItem("/links")}
                        >
                            Links
                        </Nav.Link>
                        <Nav.Link
                            className={checkSelectedItem("/socialLinks")}
                            onClick={() => history.push("/socialLinks")}
                        >
                            Social Links
                        </Nav.Link>
                        <Nav.Link
                            className={checkSelectedItem("/analytics")}
                            onClick={() => history.push("/analytics")}
                        >
                            Analytics
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>


                <Navbar.Collapse className="justify-content-end">

                    <NavDropdown title={name} id="collasible-nav-dropdown mx-5">
                        <NavDropdown.Item onClick={editProfile}>
                            Edit Profile
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};
export default InternalNavbar;
