export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function validatePassword(password) {
  const pattern = /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/;
  return pattern.test(String(password));
}

export function isAlphaNumeric(username) {
  // var regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
  var regExp = /^[ A-Za-z0-9_@./#&+-]*$/;
  const resReturn = regExp.test(String(username));
  return resReturn;
}
export const createFormDataObject = (formInputData) => {
  const formData = new FormData();
  for (const key in formInputData) {
    formData.append(key, formInputData[key]);
  }
  return formData;
};

export const convertDateToFormatedString = date => {
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const year = date.getFullYear()
  return year + '-' + month + '-' + d 
}