import React, { useState,useEffect } from "react";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Form, Col, Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import mastercard from "../../assets/images/mastercard_image.svg";
import visacard from "../../assets/images/visa_credit_card_image.svg";
import american from "../../assets/images/american_express_image.svg";
import { postDataWithAuth } from "../../utilis/api";
import { BsFillLockFill } from "react-icons/bs";
import Logo from "../../assets/images/CryptoLogo.svg";
import Copyright from "../../assets/images/Copyrights.svg";
import images from "../../assets/images/mobileSideImages.svg";
import { getDataWithAuth } from "../../utilis/api";
import ProgressBar from "../../assets/images/P5.svg";
// import "./BillingDetailsStyle.css";
import LinkButton from "../LinkButton";
import { useMediaQuery } from "@material-ui/core"

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    let history = useHistory();
    const [data, setData] = useState({
        name:"",
        email:"",
        cardholder: "",
    });
    const [checkCard, setCheckCard] = useState({
        number: false,
        expiry: false,
        cvc: false,
    });
    const isMobile = useMediaQuery("(max-width: 430px)");
    const userData = async () => {
        const response = await getDataWithAuth("/api/v1/users/me");
        console.log("res", response);
        setData(response.data.data.user)
    };

    useEffect(() => {
        userData();
    }, []);

    const submitPaymentMethod = async (data, cardBrand, cardNumber) => {
        setIsLoading(true);
        const response = await postDataWithAuth(
            `/api/v1/payments/create_customer`,
            data
        );
        if (!response.success) {
            enqueueSnackbar("Couldn't update data", {
                variant: "error",
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        history.push({
            pathname: '/subscribe',
            state: { cardBrand: cardBrand, cardNumber: cardNumber }
          })
    };

    const onChangeHandle = (event) => {
        const { name, value } = event.target;

        setData({ ...data, [name]: value });
    };

    const onChangeHandleCard = (event) => {

        if(event.elementType === "cardNumber") {
            if (event.complete) {
                setCheckCard({
                    ...checkCard,
                    number: true
                })
            } else {
                setCheckCard({
                    ...checkCard,
                    number: false
                })
            }

        } else if(event.elementType === "cardExpiry") {
            if (event.complete) {
                setCheckCard({
                    ...checkCard,
                    expiry: true
                })
            } else {
                setCheckCard({
                    ...checkCard,
                    expiry: false
                })
            }

        } else if(event.elementType === "cardCvc") {
            if (event.complete) {
                setCheckCard({
                    ...checkCard,
                    cvc: true
                })
            } else {
                setCheckCard({
                    ...checkCard,
                    cvc: false
                })
            }

        }
    };

    const handleCheckCard = () => {
        if (checkCard.number && checkCard.expiry && checkCard.cvc) {
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            // return;
        }
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        let cardElement = null;
        cardElement = { ...elements.getElement(CardCvcElement) };
        cardElement = { ...cardElement, ...elements.getElement(CardExpiryElement) };
        cardElement = { ...cardElement, ...elements.getElement(CardNumberElement) };

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });
        if (error) {
        } else {
            const data = {
                payment_method_id: paymentMethod.id,
            };
            const cardBrand = paymentMethod.card.brand;
            const cardNumber = paymentMethod.card.last4;
            submitPaymentMethod(data, cardBrand, cardNumber);
            console.log("[PaymentMethod]", paymentMethod);
        }
    };

    return (
        <div style={{ height: "100vh" }}>
            <div className="row mx-0 my-0">
                <div
                    className={`col-lg-4 col-md-5  col-sm-12  col-xs-12  col d-flex justify-content-center ${!isMobile ? "mainBackground" :""}`}
                >
                    <div className="row d-flex flex-column  align-items-center justify-content-center ">
                        <div>
                            <img
                                src={Logo}
                                className={!isMobile ? "pb-3 mb-5" :"mt-3"}
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                        {!isMobile ? 
                        <>
                        <div>
                            <img
                                src={images}
                                className="signupStyle "
                                style={{ height: "60vh" }}
                            />
                        </div>
                        <div className="pt-3 mt-5 ">
                        <p className="pt-3" style={{color:"#246bd6"}}>Copyright © 2022 CryptoLinx Ltd. All rights reserved.</p>
                        </div>
                        </>
                        :""
                        }
                    </div>
                </div>

                <div
                    className=" col-md-8 col-sm-12 mt-5 scrollClass "
                    style={{ height: "90vh", overflow: "scroll" }}
                >
                    <div className="d-flex justify-content-center">
                      <img src={ProgressBar} height="60px" width="100%" className="pb-3"/>
                      </div>
                    <p className=" d-flex justify-content-center flex-column align-items-center billingDetailStyle billingStyle pt-2">
                        {" "}
                        Billing Details
                    </p>
                    <Form
                        className={`d-flex w-100 ${!isMobile ?"pl-5" : ""} justify-content-between flex-column`}
                        onSubmit={handleSubmit}
                    >
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="mt-4 EmailStyleSignup">
                                Full Name
                            </Form.Label>
                            <Form.Control
                                placeholder="Enter Full Name"
                                name="name"
                                required
                                onChange={onChangeHandle}
                                className={!isMobile ? "inputFieldSignup w-75" :"inputFieldSignupMobile w-100" }
                                value={data.name}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="mt-4 EmailStyleSignup">Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                required
                                className={!isMobile ? "inputFieldSignup w-75" :"inputFieldSignupMobile w-100" }
                                name="email"
                                onChange={onChangeHandle}
                                 value={data.email}
                            />
                        </Form.Group>
                        <Form.Label className="mt-4 EmailStyleSignup">
                            Card Number*
                        </Form.Label>
                        <div className={`${!isMobile ? "w-75" : "w-100"}`}>
                            <CardNumberElement className={!isMobile ? "cardNumberStyle pt-3":"cardNumberStyleMobile pt-3"} name="card_number"
                                onChange={onChangeHandleCard} />
                        </div>
                        <div className={`d-flex justify-content-between ${!isMobile ? "w-75" : "w-100"} mt-3`}>
                            <div className={`${!isMobile ? "w-75 pr-3" : "w-75 pr-3"}`}>
                                <Form.Label className="mt-4 EmailStyleSignup"

                                >
                                    Expiry date (mm/yy)
                                </Form.Label>
                                <CardExpiryElement className={!isMobile ? "cardNumberStyle pt-3":"cardNumberStyleMobile pt-3"} onChange={onChangeHandleCard} />
                            </div>
                            <div className={`${!isMobile ? "w-25" : "w-25"}`}>
                                <Form.Label className="mt-4 EmailStyleSignup ">CVC</Form.Label>
                                <CardCvcElement className={!isMobile ? "cardNumberStyle pt-3":"cardNumberStyleMobile pt-3"} onChange={onChangeHandleCard} />
                            </div>
                        </div>

                        <Form.Row>
                            <Col>
                                <Form.Label className="mt-4 pt-3 EmailStyleSignup">
                                    Card Holder Name*
                                </Form.Label>
                                <Form.Control
                                    placeholder="Enter Card Holder Name"
                                    name="cardholder"
                                    required
                                    onChange={onChangeHandle}
                                    className={!isMobile ? "inputFieldSignup w-75" :"inputFieldSignupMobile w-100" }
                                />
                            </Col>
                        </Form.Row>
                        <div className="ml-3 mt-4 securityStyle">
                            <BsFillLockFill className="mb-1" /> All your information is safe and secure
                        </div>
                        <div className="securityStyle ml-3 mt-4">
                        To verify your card, a small amount may be charged to it. 
                        After verification, the amount will be automatically refunded.
                        </div>
                        <div className="ml-3 securityStyle mt-2">
                            We accept:
                            <br />
                            <img src={mastercard} height="40px" width="40px"/>
                            <img src={american} height="40px" width="40px"/>
                            <img src={visacard} height="32px" width="40px"/>
                        </div>
                        <div className={`d-flex justify-content-center align-items-center mt-3 mb-3 ${!isMobile ? "w-75" : "w-100"}`}>
                            <LinkButton
                                className="mt-4 submitButtonSignup"
                                variant="primary"
                                type="submit"
                                label="Add Card"
                                showSpinner={isLoading}
                                disabled={
                                    !data.name || !data.email|| !data.cardholder || handleCheckCard()
                                }
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};
export default CheckoutForm;
