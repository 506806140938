import React, { useState, useEffect } from "react";
import { DateRangePicker } from 'react-date-range'
import InternalNavbar from "../InternalNavbar";
import { convertDateToFormatedString } from '../../utilis/Helper'
import LinkButton from "../LinkButton";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Card, Modal, Button } from "react-bootstrap";
import { getDataWithAuth } from "../../utilis/api";
import NewsIcon from "../../assets/images/NewsletterIcon.png";
import fb from "../../assets/images/fb.png";
import youtube from "../../assets/images/youtube.png";
import twitter from "../../assets/images/twitter.png";
import telegram from "../../assets/images/telegram.png";
import Reddit from "../../assets/images/Rebbit.png";
import Medium from "../../assets/images/Medium.png";
import linkdin from "../../assets/images/linkdin.png";
import instagram from "../../assets/images/instagram.png";
import Discord from "../../assets/images/Discord.png";
import Github from "../../assets/images/github.png";
import Navbarr from "../Navbarr/index";
import "./AnalyticsStyle.css"

const Analytics = () => {
    const [data, setData] = useState({
        user: [{
        }],
        links: []
    });
    const [dataAnalytics, setDataAnalytics] = useState({
        user: [{
        }],
        default_links: []
    });
    const [modalShow, setModalShow] = React.useState(false);
    const dateOffset = 24 * 60 * 60 * 1000 * 10;
    const start = new Date();
    const end = new Date();
    start.setTime(end.getTime() - dateOffset)
    const [query, setQuery] = useState({
        start_date: convertDateToFormatedString(start),
        end_date: convertDateToFormatedString(end)
    })

    const onDateSelect = (startDate, endDate) => {
        const startDateStr = convertDateToFormatedString(startDate)
        const endDateStr = convertDateToFormatedString(endDate)
        setQuery({
            ...query,
            start_date: startDateStr,
            end_date: endDateStr,
            start: startDate,
            end: endDate
        })
    }

    const handleSelect = ranges => {
        onDateSelect(ranges.selection.startDate, ranges.selection.endDate)
    }

    useEffect(() => {
        if (query.start_date) {
            fetchData()
            fetchDataAnalytics()
        }
    }, [query])

    const fetchData = async () => {
        const response = await getDataWithAuth(`/api/v1/users/analytics?start_date=${query.start_date}&end_date=${query.end_date}`)
        if (!response.success) {
            return;
        }
        setData(response.data);
    }

    const fetchDataAnalytics = async () => {
        const response = await getDataWithAuth(`/api/v1/users/social_links_analytics?start_date=${query.start_date}&end_date=${query.end_date}`)
        if (!response.success) {
            return;
        }
        setDataAnalytics(response.data);
    }


    useEffect(() => {
        fetchData()
        fetchDataAnalytics()
    }, [])

    const allSocialIcon = (defaultLink) => {
        return defaultLink.title == "Medium "
            ? socialIconLink( Medium)
            : defaultLink.title == "Reddit"
                ? socialIconLink( Reddit)
                : defaultLink.title == "Facebook"
                    ? socialIconLink( fb)
                    : defaultLink.title == "GitHub"
                        ? socialIconLink( Github)
                        : defaultLink.title == "Discord"
                            ? socialIconLink( Discord)
                            : defaultLink.title == "Instagram Handle"
                                ? socialIconLink( instagram)
                                : defaultLink.title == "LinkedIn"
                                    ? socialIconLink( linkdin)
                                    : defaultLink.title == "Youtube"
                                        ? socialIconLink( youtube)
                                        : defaultLink.title == "Twitter Handle"
                                            ? socialIconLink( twitter)
                                            : defaultLink.title == "Telegram"
                                                ? socialIconLink( telegram)
                                                : "";

        function socialIconLink(icon) {
            return (
                <img src={icon} className="socialIconAnalytics" />
            );
        }
    };


    return <>
        <div className="mainDivScroll " style={{ overflow: "hidden" }}>
            <div>
                <InternalNavbar />
            </div>
            <div className="mt-5 d-flex flex-column justify-content-center align-items-center w-100">
                <div className="pt-5 d-flex flex-column justify-content-center align-items-center widthClass">
                    <div className="lifetimeAnalyticsContainer my-3">
                        <div className="lifeTimeAnalyticsText">Lifetime Analytics</div>
                        <div className="lifetimeAnalyticsInnerContainer">
                            <div className="d-flex flex-column align-items-center">
                                <div className="d-flex align-items-center"> <div className="purpleColor"></div> Total Views: </div>
                                <div className="boldClass">{data.user[0].total_views}</div>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <div className="d-flex align-items-center"> <div className="greenColor"></div> Total Clicks: </div>
                                <div className="boldClass">{data.user[0].total_clicks + dataAnalytics.user[0].total_clicks_default}</div>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <div className="d-flex align-items-center"> <div className="blueColor"></div> CTR: </div>
                                <div className="boldClass">{(data.user[0].total_clicks === 0 || data.user[0].total_views === 0) ? "N/A" : ((data.user[0].total_clicks / data.user[0].total_views) * 100).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-50">
                        <LinkButton
                            label={query.start_date + ' - ' + query.end_date}
                            onClick={() => setModalShow(true)}
                            className="analyticsButton mt-3 mb-5 "
                        />
                    </div>
                    <div className="backgroundColor activityOuterContainer pb-3 mb-3">
                        <div className="cardsContainer">
                            <div className="py-3 activityText" >Links</div>
                            <Card className="linksCardStyle p-2">
                                <div className="d-flex justify-content-around w-100 p-2">
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center fontSize12"> <div className="greenColor"></div> Total Views: </div>
                                        <div className="fontSize12 boldClass">{data.user[0].total_views_searched_base}</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center fontSize12"> <div className="purpleColor"></div> Total Clicks: </div>
                                        <div className="fontSize12 boldClass">{data.user[0].total_clicks_searched_base}</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center fontSize12"> <div className="blueColor"></div> CTR: </div>
                                        <div className="fontSize12 boldClass"> {(data.user[0].total_clicks_searched_base === 0 || data.user[0].total_views_searched_base === 0) ? "N/A" : ((data.user[0].total_clicks_searched_base / data.user[0].total_views_searched_base) * 100).toFixed(2)}</div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="cardsContainer">
                            {data.links.map((item) => (
                                <Card className="linksCardStyle p-2">
                                    <div className="d-flex justify-content-between">
                                        <div className="fontSize12 d-flex align-items-center"> {item.title}  </div>
                                        <div className="d-flex">
                                            <div className="d-flex flex-column align-items-center mx-4">
                                                <div className="d-flex align-items-center fontSize12"> <div className="greenColor"></div> Total Clicks: </div>
                                                <div className="fontSize12 boldClass"> {item.total_clicks_searched_base}</div>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <div className="d-flex align-items-center fontSize12"> <div className="purpleColor"></div> Unique Clicks: </div>
                                                <div className="fontSize12 boldClass">{item.unqiue_clicks_searched_base}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>

                    <div className="backgroundColor activityOuterContainer pb-3">
                        <div className="cardsContainer">
                            <div className="py-3 activityText" >Social Links</div>
                            <Card className="linksCardStyle p-2">
                                <div className="d-flex justify-content-around w-100 p-2">
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center fontSize12"> <div className="greenColor"></div> Total Views: </div>
                                        <div className="fontSize12 boldClass">{dataAnalytics.user[0].total_views_searched_base}</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center fontSize12"> <div className="purpleColor"></div> Total Clicks: </div>
                                        <div className="fontSize12 boldClass">{dataAnalytics.user[0].total_clicks_searched_base_default}</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex align-items-center fontSize12"> <div className="blueColor"></div> CTR: </div>
                                        <div className="fontSize12 boldClass"> {(dataAnalytics.user[0].total_clicks_searched_base_default === 0 || dataAnalytics.user[0].total_views_searched_base === 0) ? "N/A" : ((dataAnalytics.user[0].total_clicks_searched_base_default / dataAnalytics.user[0].total_views_searched_base) * 100).toFixed(2)}</div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="cardsContainer">
                            {dataAnalytics.default_links.map((item, i) => (
                                <Card className="linksCardStyle p-2">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div> {allSocialIcon (item)}</div>
                                            <div className="fontSize12 d-flex align-items-center"> {item.title} </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="d-flex flex-column align-items-center mx-4">
                                                <div className="d-flex align-items-center fontSize12"> <div className="greenColor"></div> Total Clicks: </div>
                                                <div className="fontSize12 boldClass"> {item.total_clicks_searched_base}</div>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <div className="d-flex align-items-center fontSize12"> <div className="purpleColor"></div> Unique Clicks: </div>
                                                <div className="fontSize12 boldClass">{item.unqiue_clicks_searched_base}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Select Date
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center align-items-center">
                    <DateRangePicker
                        dateDisplayFormat="yyyy/MM/dd"
                        ranges={[
                            {
                                startDate: query.start,
                                endDate: query.end,
                                key: 'selection'
                            }
                        ]}
                        startDatePlaceholder="-"
                        endDatePlaceholder="-"
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    </>;
}
export default Analytics;