import axios from "axios";
import { getToken } from "./LocalStorage";

export const BASE_URL = process.env.REACT_APP_API_LINK;;

axios.defaults.baseURL = BASE_URL;

const setApiHeader = async () => {
  const token = await getToken();
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (exp) {}
};

export const postData = async (url, data) => {
  try {
    const response = await axios.post(url, data);
    console.log("consoleee", response);
    return {
      data: response.data,
      error: "",
      success: true,
    };
  } catch (exp) {
    console.log("error", exp.response);
    return {
      success: false,
      error: exp.response.data.data,
    };
  }
};

export const getDataWithAuth = async (url, data) => {
  await setApiHeader();
  try {
    const response = await axios.get(url, data);
    return {
      data: response.data,
      error: "",
      success: true,
    };
  } catch (exp) {
    return {
      success: false,
      error: exp.response.data.data,
    };
  }
};

export const postDataWithAuth = async (url, data) => {
  await setApiHeader();
  try {
    const response = await axios.post(url, data);
    return {
      data: response.data,
      error: "",
      success: true,
    };
  } catch (exp) {
    return {
      success: false,
      error: exp.response.data.data,
    };
  }
};

export const deleteAPIWithAuth = async (url) => {
  await setApiHeader();
  try {
    const response = await axios.delete(url);
    return {
      data: response.data,
      error: "",
      success: true,
    };
  } catch (exp) {
    return {
      success: false,
      error: exp.response.data.data,
    };
  }
};

export const putDataWithAuth = async (url, data) => {
  await setApiHeader();
  try {
    const response = await axios.put(url, data);
    return {
      data: response.data,
      error: "",
      success: true,
    };
  } catch (exp) {
    return {
      success: false,
      error: exp.response.data.data,
    };
  }
};

export const getData = async (url, data) => {
  try {
    const response = await axios.get(url, data);
    return {
      data: response.data,
      error: "",
      success: true,
    };
  } catch (exp) {
    return {
      success: false,
      error: exp.response.data.data,
    };
  }
};
