import { React, useState, useEffect } from "react";
import InternalNavbar from "../InternalNavbar";
import ThreeDot from "../../assets/images/three-dot.png";
import { putDataWithAuth, getDataWithAuth } from "../../utilis/api";
import { useSnackbar } from "notistack";
import { Card, Spinner } from "react-bootstrap";
import ShowAllLinks from "../ShowAllLinks";
import SocialLinksMobileView from "../SocialLinkMobileView/SocialLinksMobileView";
import mobileImage from "../../assets/images/a5.svg";
import { useMediaQuery } from "@material-ui/core"
import "./SocialLinksStyle.css";
const SocialLinks = () => {
  const [linksArray, setLinksArray] = useState([{ title: "", url: "" }]);
  const [tempLink, setTempLink] = useState(null);
  const [tempLinkDisable, setTempLinkDisable] = useState(null);
  const [tempLinkUrl, setTempLinkUrl] = useState(null);
  const [change, setChange] = useState(false);
  const isMobile = useMediaQuery("(max-width: 430px)");
  const [updateLink, setUpdateLink] = useState({});
  const [isLoadingAllLinks, setIsLoadingAllLinks] = useState(false);
  const [profileLink, setProfileLink] = useState();

  const [userId, setUserId] = useState();
  const [disable, setDisable] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const updateLinkChange = (event) => {
    const { name, value, id, checked } = event.target;

    if (event.target.name === "url") {
      setTempLinkUrl({ ...tempLinkUrl, name, value, id });
    } else {
      setTempLinkDisable({ ...tempLinkUrl, name, value, id, checked });
    }
  };
  useEffect(() => {
    if (disable && tempLinkDisable != null) {
      const id = tempLinkDisable.id;
      submitLinkData(id);
      setDisable(false);
    }
  }, [disable]);
  const userData = async () => {
    const response = await getDataWithAuth("/api/v1/users/me");
    if (!response.success) {
      return;
    }
    setProfileLink(response.data.data.user.username);

    setUserId(response.data.data.user.id);
  };
  useEffect(() => {
    userData();
    showAllLinks();
  }, []);

  const showAllLinks = async () => {
    setIsLoadingAllLinks(true);

    const response = await getDataWithAuth(
      `/api/v1/users/${userId}/default_links`
    );
    if (!response.success) {
      setIsLoadingAllLinks(false);

      return;
    }
    setLinksArray(response.data.data.data);
    setIsLoadingAllLinks(false);
  };
  const submitLinkData = async (id) => {
    const response = await putDataWithAuth(
      `/api/v1/users/${userId}/default_links/${id}`,
      updateLink
    );
    if (!response.success) {
      enqueueSnackbar("Couldn't update link", {
        variant: "error",
      });
      return;
    }
    enqueueSnackbar("Updated successfully", {
      variant: "info",
    });

    showAllLinks();
    setTempLink(null);
    setTempLinkUrl(null);
    setTempLinkDisable(null);
    setUpdateLink({});
    setChange(!change);
  };
  // useEffect(() => {
  //   showAllLinks();
  // }, []);
  useEffect(() => {
    if (tempLink != null) {
      const name = tempLink.name;
      const value = tempLink.value;
      setUpdateLink({ ...updateLink, [name]: value });
    }
    if (tempLinkUrl != null) {
      const name = tempLinkUrl.name;
      const value = tempLinkUrl.value;
      setUpdateLink({ ...updateLink, [name]: value });
    }
    if (tempLinkDisable != null) {
      const name = tempLinkDisable.name;
      const value = tempLinkDisable.value;
      const disable = `${tempLinkDisable.checked}`;
      const id = tempLinkDisable.id;
      setUpdateLink({ ...updateLink, [name]: disable });

      setDisable(true);
    }
  }, [tempLink, tempLinkUrl, tempLinkDisable]);
  return (
    <>
      <div className="mainDivScroll " style={{ overflow: "hidden" }}>
        <div>
          <InternalNavbar />
        </div>
        <div className="container mt-5"></div>
        <div className="container-fluid">
          <div className="row pt-5">
            <div className="col-md-6 col-sm-12 mt-2">
              <div className="">
                {isLoadingAllLinks ? (
                  <div className="d-flex justify-content-center w-100 pt-5">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <div className="w-100 socialLinksScrollableDiv">
                    {linksArray.map((item) => (
                      <Card className="socialLinksCardStyle pt-2">
                        <div className="d-flex align-items-center">
                          <div class="verticalLine"></div>
                          <div className="d-flex flex-column w-100">
                            <div className="d-flex justify-content-between w-100 px-3">
                              <p>{item.title == "Twitter Handle" ? "Twitter" : item.title == "Instagram Handle" ? "Instagram" : item.title}</p>
                              <div className="">
                                <label class="socialSwitch">
                                  <input
                                    name="disable"
                                    type="checkbox"
                                    checked={
                                      tempLinkDisable != null &&
                                      tempLinkDisable.id == item.id
                                        ? tempLinkDisable.checked
                                        : item.disable
                                    }
                                    onChange={updateLinkChange}
                                    id={
                                      tempLinkDisable != null &&
                                      tempLinkDisable.id == item.id
                                        ? tempLinkDisable.id
                                        : item.id
                                    }
                                    value={
                                      tempLinkDisable != null &&
                                      tempLinkDisable.id == item.id
                                        ? tempLinkDisable.value
                                        : !item.disable
                                    }
                                    onBlur={(e) => {
                                      submitLinkData(item.id);
                                    }}
                                  />
                                  <span class="socialSlider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="d-flex px-3 pb-3">
                              <input
                                name="url"
                                type="text"
                                id={
                                  tempLinkUrl != null &&
                                  tempLinkUrl.id == item.id
                                    ? tempLinkUrl.id
                                    : item.id
                                }
                                placeholder="Url"
                                className="titleStyle w-50"
                                value={
                                  tempLinkUrl != null &&
                                  tempLinkUrl.id == item.id
                                    ? tempLinkUrl.value
                                    : item.url
                                }
                                onChange={updateLinkChange}
                                onBlur={(e) => {
                                  submitLinkData(item.id);
                                }}
                              />
                            </div>

                            <div className="d-flex justify-content-end w-100"></div>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {!isMobile ? 
            <div className="col-lg-6 col-md-12">
              <div className="phoneLinks ">
                <img src={mobileImage} className="" alt="image" />
                <ShowAllLinks
                  change={change}
                  profileLink={profileLink}
                  flag={false}
                  className="  "
                  dontAddView
                  socialLink
                  apply50Width
                />
              </div>
            </div>
            :""
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default SocialLinks;
