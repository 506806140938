import React, { useState, useEffect } from "react";
import { createFormDataObject } from "../../utilis/Helper";
import LinkButton from "../LinkButton/LinkButton";
import { postDataWithAuth, putDataWithAuth, getDataWithAuth } from "../../utilis/api";
import { Form, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { removeToken } from "../../utilis/LocalStorage";
import checbox from "../../assets/images/featureCheckbox.png";
import "./EditProfileStyle.css";
import DefaultProfilePic from "../../assets/images/default_profile_pic.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import { GiCancel } from "react-icons/gi";
import InternalNavbar from "../InternalNavbar";
import { useMediaQuery } from "@material-ui/core"


const EditProfile = () => {

  let history = useHistory();
  const [subscription, setSubscription] = useState("");
  const [profileImage, setProfileImage] = useState()
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = useState();
  const isMobile = useMediaQuery("(max-width: 430px)");
  const [data, setData] = useState({
    user: {
      name: "",
      email: "",
      subscription_plan: ""
    },
    profile_pic: ""
  });
  const [imgSrc, setImgSrc] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showCancelSpinner, setShowCancelSpinner] = useState(false)

  const currentUser = async () => {
    const response = await getDataWithAuth("/api/v1/users/me");
    if (!response.success) {
      return;
    }
    setData(response.data.data.user);
    setProfileImage(response.data.data.profile_pic)
    setUserId(response.data.data.user.id);
    setSubscription(response.data.data.user.subscription_plan)
  };

    useEffect(() => {
        currentUser();
    }, []);

  const handleDataChange = (e) => {
    const { value, name, files } = e.target;
    if (name == "profile_pic") {
      setData({ ...data, profile_pic: files[0] });
      setProfileImage(files[0]);
    } else {
      setData({ ...data, [name]: value });
    }
    if (name === "profile_pic") {
      var file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        setImgSrc([reader.result]);
      };
    }
  };


  const updateProfile = async () => {
    setShowSpinner(true);
    const formData = createFormDataObject(data);
    for (var value of formData.values()) {
      console.log(value);
    }
    const response = await putDataWithAuth(`/api/v1/users/${userId}`, formData);
    if (!response.success) {
      setShowSpinner(false);
      enqueueSnackbar(response.error.message, {
        variant: "error",
      });
      return;
    }
    currentUser();
    setShowSpinner(false);
    enqueueSnackbar("Updated successfully", {
      variant: "info",
    });
  };

  const setPriceValue = () => {
    if (subscription == "influencer") {
      return "$9";
    } else if (subscription == "company") {
      return "$19";
    } else if (subscription == "exchange") {
      return "$29";
    }
  };


  const cancelSubscription = async () => {
    setShowCancelSpinner(true);
    const response = await postDataWithAuth("/api/v1/payments/cancel_subscription");
    if (!response.success) {
      setShowCancelSpinner(false);
      enqueueSnackbar("error", {
        variant: "error",
      });
      return;
    }
    setShowCancelSpinner(false);
    await removeToken();
    history.push("/login");
    enqueueSnackbar("Subscription Cancelled successfully", {
      variant: "info",
    });
  };

  return (
    <>
      <div className="componentHeight ">
      <div>
                    <InternalNavbar />
                </div>
        <div className="row w-100 mx-0 mb-0 mt-5">
          <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 scrollClass ">
            <div className="d-flex flex-column justify-content-center align-items-center w-100" >
              <div className="width60 mt-5">
                <h6 className="signIn ">Edit Profile</h6>
                <div className={!isMobile ? "d-flex align-items-center justify-content-center":""}>
                  <div className={isMobile ? "d-flex justify-content-center mb-3" :"d-flex justify-content-center"}>
                    <img
                      src={imgSrc ? imgSrc : DefaultProfilePic}
                      height={100}
                      width={120}
                      alt="profile_pic"
                      value={data.profile_pic}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <input
                      src={imgSrc ? imgSrc : data.profile_pic}
                      alt="Update Image: "
                      type="file"
                      name="profile_pic"
                      onChange={handleDataChange}
                      className="pl-2"
                    // style={{ visibility: "hidden" }}
                    />
                  </div>
                </div>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="labelStyle mt-3">Full Name:</Form.Label>
                    <Form.Control
                      placeholder="Enter your Name"
                      required
                      className={!isMobile ? "inputFieldStyle":"inputFieldStyleMobile"}
                      name="name"
                      onChange={handleDataChange}
                      value={data.name}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="labelStyle mt-1">Email:</Form.Label>
                    <Form.Control
                      placeholder="Enter your Email"
                      required
                      className={!isMobile ? "inputFieldStyle":"inputFieldStyleMobile"}
                      name="email"
                      onChange={handleDataChange}
                      value={data.email}
                    />
                  </Form.Group>

                </Form>
              </div>
              <div className="d-flex justify-content-center align-items-center width60 ">
                <LinkButton
                  variant="primary"
                  type="submit"
                  onClick={updateProfile}
                  className="submitButtonSignup mt-3 mb-5 "
                  showSpinner={showSpinner}
                  label="Update"
                />
              </div>

              <div className="width60">
              <div className="headingSubscription">Subscription</div>
              </div>
              <div className="w-100 d-flex justify-content-center">
                <Card className={!isMobile ?"featureCardStyleEditProfile p-3" :"featureCardStyle w-100 p-3"}>
                    { !isMobile ?
                  <div className="d-flex justify-content-around">
                    <div className="boxStyle boxSize d-flex flex-column  w-25 mt-1">
                      <p className="price priceStyle">{setPriceValue()}</p>
                      <p className="perMonthStyle">per month</p>

                    </div>
                    <div className="pl-4 w-100">
                      <p className="companiesStyle companyStyle">{data.subscription_plan}</p>
                      <div className="row">
                        <div className="col-6">
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">Guaranteed Username</div>
                          </div>
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">Connect Social Networks</div>
                          </div>
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">Link Analytics</div>
                          </div>
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">CryptoLinx Social Icon</div>
                          </div>
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">QR Code</div>
                          </div>


                        </div>
                        <div className="col-6">

                          <div className="d-flex  pt-2">
                            <img
                              src={checbox}
                              width={18}
                              height={18}
                              className="mt-1"
                            />
                            <div className="pl-2">
                              Unlimited ‘Call to Action’ Links
                            </div>
                          </div>
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">Update Links Instantly</div>
                          </div>
                          <div className="d-flex  pt-2">
                            <img
                              src={checbox}
                              width={18}
                              height={18}
                              className="mt-1"
                            />
                            <div className="pl-2">Remove CryptoLinx Branding</div>
                          </div>
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">Simple Dashboard</div>
                          </div>
                          <div className="d-flex align-items-center pt-2">
                            <img src={checbox} width={18} height={18} />
                            <div className="pl-2">Flexible Plans</div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                      <p className="companiesStyle companyStyle">{data.subscription_plan}</p>
                  <div className="boxStyle boxSize d-flex flex-column  w-25 mt-1">
                    <p className="price priceStyle">{setPriceValue()}<span>/mo</span></p>
                  </div>
                  <div className="pl-4 w-100">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">Guaranteed Username</div>
                        </div>
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">Connect Social Networks</div>
                        </div>
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">Link Analytics</div>
                        </div>
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">CryptoLinx Social Icon</div>
                        </div>
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">QR Code</div>
                        </div>

                        <div className="d-flex  pt-2">
                          <img
                            src={checbox}
                            width={18}
                            height={18}
                            className="mt-1"
                          />
                          <div className="pl-2">
                            Unlimited ‘Call to Action’ Links
                          </div>
                        </div>
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">Update Links Instantly</div>
                        </div>
                        <div className="d-flex  pt-2">
                          <img
                            src={checbox}
                            width={18}
                            height={18}
                            className="mt-1"
                          />
                          <div className="pl-2">Remove CryptoLinx Branding</div>
                        </div>
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">Simple Dashboard</div>
                        </div>
                        <div className="d-flex align-items-center pt-2">
                          <img src={checbox} width={18} height={18} />
                          <div className="pl-2">Flexible Plans</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
}
                </Card>

              </div>
              <div className={isMobile ? "w-100 d-flex justify-content-center":"w-25 d-flex justify-content-center"}>
                <LinkButton
                  variant="primary"
                  type="submit"
                  onClick={cancelSubscription}
                  className="cancelButton mt-3 "
                  showSpinner={showCancelSpinner}
                  label="Cancel Subscription"
                />
              </div>
            </div>
            </div>
            </div>
            </div>
        </>
    );
};
export default EditProfile;
