import React, { useState, useEffect } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  postDataWithAuth,
  getDataWithAuth,
  deleteAPIWithAuth,
  putDataWithAuth,
} from "../../utilis/api";
import AddLink from "../AddLinks/AddLinks";
import {
  Button,
  Spinner,
  Modal,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { useSnackbar } from "notistack";
import mobileImage from "../../assets/images/a5.svg";
import InternalNavbar from "../InternalNavbar";
import ShowAllLinks from "../ShowAllLinks";
import CardDrag from '../DragDrop/Card'
import update from 'immutability-helper'
import searchIcon from "../../assets/images/searchIconLinks.png";
import totalView from "../../assets/images/totalViews.png";
import "./LinksStyle.css";
import { useMediaQuery } from "@material-ui/core"

var QRCode = require("qrcode.react");

const Links = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [profileLink, setProfileLink] = useState();
  const [flag, setflag] = useState(false);
  const [Category, setCategory] = useState(false);
  const [show, setShow] = useState(false);
  const [paragraphShow, setParagraphShow] = useState(false);
  const isMobile = useMediaQuery("(max-width: 430px)");
  const [inputList, setInputList] = useState({
    title: "",
    url: "",
    link_type: "",
  });
  const [updateLink, setUpdateLink] = useState({});
  const [tempLink, setTempLink] = useState(null);
  const [linksArray, setLinksArray] = useState([
    { title: "", url: "", link_type: "" },
  ]);
  const [showAddLinkCard, setShowAddLinkCard] = useState(false);
  const [tempLinkUrl, setTempLinkUrl] = useState(null);
  const [tempCategory, setTempCategory] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteLinkId, setDeleteLinkId] = useState();
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [isLoadingAllLinks, setIsLoadingAllLinks] = useState(false);
  const [tempLinkDisable, setTempLinkDisable] = useState(null);
  const [disable, setDisable] = useState(false);
  const [change, setChange] = useState(false);
  const [userId, setUserId] = useState();
  const [totalViews, setTotalViews] = useState();
  const [uniqueViews, setuniqueViews] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("Category");
  const [isRearranging , setIsRearranging] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputList({ ...inputList, [name]: value });
  };

  const handleDropdownChange = (e) => {
    const { name } = e.target;
    setSelectedValue(name);
    const value = name;
    setInputList({ ...inputList, link_type: value });
  };

  const userData = async () => {
    const response = await getDataWithAuth("/api/v1/users/me");
    if (!response.success) {
      return;
    }
    setProfileLink(response.data.data.user.username);
    setUserId(response.data.data.user.id);
  };
  useEffect(() => {
    userData();
  }, []);

  const updateLinkChange = (event) => {
    const { name, value, id, checked } = event.target;
    if (event.target.name === "title") {
      setTempLink({ ...tempLink, name, value, id });
    } else if (event.target.name === "url") {
      setTempLinkUrl({ ...tempLinkUrl, name, value, id });
    } else if (event.target.name === "disable") {
      setTempLinkDisable({ ...tempLinkDisable, name, value, id, checked });
    } else {
      setTempCategory({ ...tempCategory, name, id });
    }
  };

  useEffect(() => {
    if (disable && tempLinkDisable != null) {
      const id = tempLinkDisable.id;
      submitLinkData(id);
      setDisable(false);
    }
  }, [disable]);

  useEffect(() => {
    if (Category) {
      const id = tempCategory.id;
      submitLinkData(id);
      setCategory(false);
    }
  }, [Category]);

  useEffect(() => {
    if (tempLink != null) {
      const name = tempLink.name;
      const value = tempLink.value;
      setUpdateLink({ ...updateLink, [name]: value });
    }
    if (tempLinkUrl != null) {
      const name = tempLinkUrl.name;
      const value = tempLinkUrl.value;
      setUpdateLink({ ...updateLink, [name]: value });
    }
    if (tempCategory != null) {
      const value = tempCategory.name;
      const id = tempCategory.id;
      setUpdateLink({ ...updateLink, link_type: value });
      setCategory(true);
    }

    if (tempLinkDisable != null) {
      const name = tempLinkDisable.name;
      const value = tempLinkDisable.value;
      const disable = `${tempLinkDisable.checked}`;
      const id = tempLinkDisable.id;
      setUpdateLink({ ...updateLink, [name]: disable });
      setDisable(true);
    }
  }, [tempLink, tempLinkUrl, tempLinkDisable, tempCategory]);

  const addLink = async () => {
    setIsAddingLink(true);
    const response = await postDataWithAuth(`/api/v1/users/${userId}/links`, {
      title: inputList.title,
      url: inputList.url,
      link_type: inputList.link_type,
      disable: true
    });
    console.log("fcc===",response)
    if (!response.success) {
      enqueueSnackbar("Couldn't add link", {
        variant: "error",
      });
      setIsAddingLink(false);
      return;
    }
    enqueueSnackbar("Added successfully", {
      variant: "info",
    });
    setChange(!change);
    showAllLinks();
    setInputList({ title: "", url: "", link_type: "" });
    setShowAddLinkCard(false);
    setIsAddingLink(false);
  };

  const deleteLink = async () => {
    setIsDeleting(true);
    const response = await deleteAPIWithAuth(
      `/api/v1/users/${userId}/links/${deleteLinkId}`
    );
    if (!response.success) {
      setIsDeleting(false);
      enqueueSnackbar("Couldn't delete link", {
        variant: "error",
      });
      return;
    }
    enqueueSnackbar("Deleted successfully", {
      variant: "info",
    });
    setChange(!change);
    showAllLinks();
    setIsDeleting(false);
    setDeleteLinkId();
  };

  const submitLinkData = async (id) => {
    const response = await putDataWithAuth(
      `/api/v1/users/${userId}/links/${id}`,
      updateLink
    );
    console.log("submitLink===",response)
    if (!response.success) {
      enqueueSnackbar("Couldn't update link", {
        variant: "error",
      });
      return;
    }
    enqueueSnackbar("Updated successfully", {
      variant: "info",
    });
    setChange(!change);
    showAllLinks();
    setTempLink(null);
    setTempLinkUrl(null);
    setTempLinkDisable(null);
    setUpdateLink({});
  };

  const addInList = () => {
    setShowAddLinkCard(true);
  };
  const addTotalViews = async () => {
    const response = await getDataWithAuth(`/api/v1/users/${userId}/views`);
    if (!response.success) {
      return;
    }
    setTotalViews(response.data.data.total_views);
    setuniqueViews(response.data.data.unique_views);
  };
  useEffect(() => {
    addTotalViews();
  }, []);

  useEffect(() => {
    addTotalViews();
  }, [totalViews, uniqueViews]);

  const showAllLinks = async () => {
    setIsLoadingAllLinks(true);
    const response = await getDataWithAuth(`/api/v1/users/${userId}/links`);
    console.log("yyyy===",response)
    if (!response.success) {
      setIsLoadingAllLinks(false);
      return;
    }
    setLinksArray(response.data);
    setIsLoadingAllLinks(false);
  };
  const para = () => {
    if (linksArray.length > 1) {
      setParagraphShow(true);
    } else if (linksArray.length < 1) {
      setParagraphShow(false);
    }
  };
  useEffect(() => {
    showAllLinks();
  }, []);

  const cancelLink = () => {
    setShowAddLinkCard(false);
  };


  const downloadQR = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "123456.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = linksArray[dragIndex]
    setLinksArray(
      update(linksArray, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      })
    )
    setIsRearranging(true);
  }

  const rearrange = async () => {
      const response = await postDataWithAuth(`/api/v1/users/${userId}/links/re_arrange`, {
        ordering_array: linksArray
      })
      if (!response.success) {
        enqueueSnackbar("Rearranged error", {
          variant: "error",
        });
        setIsRearranging(false);
        return
      }
      enqueueSnackbar("Rearranged successfully", {
        variant: "info",
      });
    setChange(!change);

      showAllLinks();
      setIsRearranging(false);

  }


 useEffect(() => {
    if(isRearranging) rearrange();

  }, [isRearranging]);



  return (
    <>
      <div className="mainDivScroll" style={{ overflow: "hidden" }}>
        <div>
          <InternalNavbar />
        </div>
        <div className="container mt-5"></div>
        <div className=" pt-5">
          <div className="container-fluid">
            <div className={!isMobile ? "row h-100 px-5" :"row h-100 px-1" }>
              <div className=" col-lg-6 col-md-12">
                <div className={isMobile ? "d-flex flex-column justify-content-between align-items-center":"d-flex justify-content-between align-items-center"}>
                  <p className={isMobile ? "analyticsStyleMobile":"analyticsStyle"}>Analytics</p>
                  <div className="d-flex justify-content-center align-items-center ">
                    <img src={totalView} height= {isMobile ? "35px" : ""} width={isMobile?"35px":""} />
                    <p className="viewsClicksStyle pt-3 pl-2">
                      Total Views:{" "}
                      <span className="viewsClicksNumericStyle">
                        {" "}
                        {totalViews}
                      </span>{" "}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center ">
                    <img src={searchIcon} height= {isMobile ? "35px" : ""} width={isMobile?"35px":""} />
                    <p className="viewsClicksStyle pt-3 pl-2">
                      Unique Views:
                      <span className="viewsClicksNumericStyle">
                        {uniqueViews}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <div className="">
                  <Button
                    className="mt-5 addNewLinkButton"
                    variant=""
                    type="submit"
                    onClick={addInList}
                  >
                    Add New Link
                  </Button>
                </div>
                { isMobile ?
                <div className=" d-flex mt-3 justify-content-around">
                  <a
                    className=""
                    target="_blank"
                    href={`${process.env.REACT_APP_LINK_BASE_URL}/${profileLink}`} rel="noreferrer"
                  >
                    {process.env.REACT_APP_LINK_BASE_URL}/{profileLink}
                  </a>
                  <div className="widthButton">
                    <DropdownButton id="dropdown-basic-button" title="Share">
                      <CopyToClipboard
                        text={`${process.env.REACT_APP_LINK_BASE_URL}/${profileLink}`}
                        onCopy={() =>
                          enqueueSnackbar("Copied", {
                            variant: "info",
                            autoHideDuration:1000
                          })
                        }
                      >
                        <Dropdown.Item>Copy Link</Dropdown.Item>
                      </CopyToClipboard>
                      <Dropdown.Item onClick={() => setModalShow(true)}>
                        Download QR Code
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
  :""
}

                {paragraphShow ? (
                  <p className="clickButtonText">
                    You don't have any links to display Click the button above to
                    add one.
                  </p>
                ) : null}
                <div className="mt-5">
                  {showAddLinkCard ? (
                    <AddLink
                      addLink={addLink}
                      handleInputChange={handleInputChange}
                      inputList={inputList}
                      cancelLink={cancelLink}
                      isAddingLink={isAddingLink}
                      onClick={para}
                      handleDropdownChange={handleDropdownChange}
                      selectedValue={selectedValue}

                    />
                  ) : null}
                </div>
                {isLoadingAllLinks ? (
                  <div className="d-flex justify-content-center w-100 pt-5">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <div className="w-100">
                    {linksArray.map((item, i) => (
                      <CardDrag
                        key={item.id}
                        index={i}
                        id={item.id}
                        item={item}
                        moveCard={moveCard}
                        tempLink={tempLink}
                        tempLinkDisable={tempLinkDisable}
                        tempLinkUrl={tempLinkUrl}
                        tempCategory={tempCategory}
                        isDeleting={isDeleting}
                        deleteLinkId={deleteLinkId}
                        setDeleteLinkId={setDeleteLinkId}
                        submitLinkData={submitLinkData}
                        updateLinkChange={updateLinkChange}
                        handleShow={handleShow}
                        

                      />

                    ))}
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-md-12">
              { !isMobile ?
                <div className=" d-flex mt-3 justify-content-center">
                  <a
                    className=""
                    target="_blank"
                    href={`${process.env.REACT_APP_LINK_BASE_URL}/${profileLink}`} rel="noreferrer"
                  >
                    {process.env.REACT_APP_LINK_BASE_URL}/{profileLink}
                  </a>
                  <div className="widthButton pl-4 ">
                    <DropdownButton id="dropdown-basic-button" title="Share">
                      <CopyToClipboard
                        text={`${process.env.REACT_APP_LINK_BASE_URL}/${profileLink}`}
                        onCopy={() =>
                          enqueueSnackbar("Copied", {
                            variant: "info",
                          })
                        }
                      >
                        <Dropdown.Item>Copy Link</Dropdown.Item>
                      </CopyToClipboard>
                      <Dropdown.Item onClick={() => setModalShow(true)}>
                        Download QR Code
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
  :""
}
                <div className="phoneLinks mt-4">
                    {!isMobile ? 
                  <img src={mobileImage}  alt='image' />
                  :""
                }
                
                  <ShowAllLinks
                    change={change}
                    profileLink={profileLink}
                    flag={flag}
                    className="  "
                    dontAddView
                  // apply75Width
                  apply50Width
                  />
                  
                </div>


              </div>

            </div>

          </div>
        </div>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              QR Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <QRCode
                id="123456"
                value="123456"
                size={290}
                level={"H"}
                includeMargin={true}
                value={`${process.env.REACT_APP_LINK_BASE_URL}/${profileLink}`}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>
              <a onClick={downloadQR}>
                {" "}
                Download <BsDownload size={17} />{" "}
              </a>
            </Button>
            <Button onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you really want to delete this?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                deleteLink();
                handleClose();
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default Links;
