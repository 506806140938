import { React, useState, useEffect } from "react";
import "./HomeStyle.css";
import { Button, Collapse, Form } from "react-bootstrap";
import Logo from "../../assets/images/Logo.png";
import Navbarr from "../Navbarr/index";
import marketingIcon from "../../assets/images/iconn4.svg";
import socialIcon from "../../assets/images/iconn3.svg";
import allUrlIcon from "../../assets/images/iconn2.svg";
import dashboardIcon from "../../assets/images/iconn1.svg";
import UserNameimg from "../../assets/images/Username.png";
import MobileIcon from "../../assets/images/a1.svg";
import MobileIcon2 from "../../assets/images/a2.svg";
import MobileIcon3 from "../../assets/images/marketing_1.svg";
import MobileIcon4 from "../../assets/images/img.svg";
import MobileIcon5 from "../../assets/images/HomeImage5.svg";
import instaIcon from "../../assets/images/insta-img.png";
import facebookIcon from "../../assets/images/facebook-img.png";
import twitterIcon from "../../assets/images/twitter-img.png";
import LinkdinIcon from "../../assets/images/linkdin-img.png";
import youtubeIcon from "../../assets/images/youtube-img.png";
import cardIcon1 from "../../assets/images/Icon1.png";
import cardIcon2 from "../../assets/images/Icon2.png";
import cardIcon3 from "../../assets/images/Icon3.png";
import dropDownIcon from "../../assets/images/Shape.svg";
import IndicatorIcon from "../../assets/images/Group462.svg";
import LinkButton from "../LinkButton";
import { getData } from "../../utilis/api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { isAlphaNumeric } from "../../utilis/Helper";
import exchanges from "../../assets/images/exchanges.png";
import companies from "../../assets/images/companies.png";
import influencer from "../../assets/images/micFlip.png";
import FeatureComponent from "../../common/featureCardComponent/FeatureCardComponent";
import userIcon from "../../assets/images/feature_username.svg";
import call from "../../assets/images/call_to_action.svg";
import social from "../../assets/images/social_network.svg";
import analytics from "../../assets/images/analytics.svg";
import sociall from "../../assets/images/cruptoIconSocial.svg";
import qr from "../../assets/images/qr_code.svg";
import update from "../../assets/images/update_instantly.svg";
import plans from "../../assets/images/plans.svg";
import remove from "../../assets/images/Group1640.svg";
import { BsChevronDown, BsChevronUp, BiArrowBack } from "react-icons/bs";
import tickIcon from "../../assets/images/tickIcon.png";
import { useMediaQuery } from "@material-ui/core";
import InstaFooter from "../../assets/images/linkdlnFooter.svg";
import TwitterFooter from "../../assets/images/twitterFooter.svg";
import YouFooter from "../../assets/images/youtubeFooter.svg";
import FacebookFooter from "../../assets/images/facebookFooter.svg";
import LinkFooter from "../../assets/images/instaFooter.svg";
import { height } from "dom-helpers";
import cryptoIcon from "../../assets/images/IconforFooter.png"
import footerIcon from "../../assets/images/FooterLogo.png"

const Home = () => {
    const initials = "Cryptolinx.io/";

    const [data, setData] = useState({
        username: `${initials}`,
    });

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);

    const [openOne, setOpenOne] = useState(false);

    const [openTwo, setOpenTwo] = useState(false);

    const [openThree, setOpenThree] = useState(false);

    const [openFour, setOpenFour] = useState(false);
    const [open5, setOpen5] = useState(false);

    const [open6, setOpen6] = useState(false);

    const [open7, setOpen7] = useState(false);

    const [open8, setOpen8] = useState(false);

    const [open9, setOpen9] = useState(false);

    const [open10, setOpen10] = useState(false);

    const [isError, setIsError] = useState(false);

    const [errorMessage, setErrorMessage] = useState();

    let history = useHistory();

    const isMobile = useMediaQuery("(max-width: 430px)");

    const onChangeHandle = (e) => {
        const { name, value } = e.target;
        const input = value;
        const slicedInput = input.slice(0, 14);
        if (slicedInput === initials) {
          if (value.length < 14) {
            setData({ ...data, [name]: initials });
          } else {
            setData({ ...data, [name]: value });
          }
        }
      };

    useEffect(async () => {
        // if (data.username.length >= 15) {
        //     setInitials("");
        // }
        if (data.username.length >= 15) {
            const response = await getData(
                `/api/v1/users/username?username=${data.username}`
            );
            if (!response.success) {
                enqueueSnackbar(response.error.message, {
                    variant: "error",
                    autoHideDuration: 500,
                });
                setIsError(true);
                setErrorMessage(response.error.message);
                return;
            }
            setIsError(false);
        }
    }, [data.username]);

    // const handleInputValue = () => {
    //     return `${initials}${data.username}`
    // }

    return (
        <>
            <div className="Nav mb-5">
                <Navbarr />
            </div>
            <div className="mx-0 my-0">
                <div className="greyColors mx-0 my-0 div-1 ">
                    <div className="boxPadding w-100 greyColors">
                        <div className="row  w-sm-100 d_block pt-5 ">
                            <div className="col col-xl-8 col-lg-8 col-xs-12 col-sm-12 col-md-12  ">
                                <h1
                                    className={
                                        !isMobile ? "headingStyles" : "headingStylesMobile"
                                    }
                                >
                                    All Your
                                    <span className="pinkColor"> Crypto Content</span>
                                </h1>
                                <h1
                                    className={
                                        !isMobile
                                            ? "headingStyles subHeadingTop"
                                            : "headingStylesMobile"
                                    }
                                >
                                    In Just One Link
                                </h1>
                                <p
                                    className={
                                        !isMobile ? "paragraphStyleTop" : "paragraphStyleTopMobile"
                                    }
                                >
                                    The best way to connect, engage and update your community.
                                </p>
                                <div
                                    class={
                                        !isMobile
                                            ? "row pt-5 dblock"
                                            : "d-flex justify-content-center flex-column align-items-center ml-5"
                                    }
                                >
                                    <div class="col-xl-4 col-xs-6 col-sm-6 col-lg-4 col-md-6 d-flex">
                                        <img
                                            width={isMobile ? "20px" : ""}
                                            height={isMobile ? "20px" : "45px"}
                                            src={marketingIcon}
                                            className={!isMobile ? "float-left" : ""}
                                            style={{ objectFit: "fill" }}
                                        />
                                        <p className="px-4 pl-2 iconMargin  blue featureFonts">
                                            Marketing Tool
                                        </p>
                                    </div>
                                    <div class="col-xl-4 col-xs-4 col-sm-4 col-lg-4 col-md-6 d-flex">
                                        <img
                                            src={socialIcon}
                                            width={isMobile ? "20px" : ""}
                                            height={isMobile ? "20px" : "45px"}
                                            className="float-left "
                                            style={{ objectFit: "fill" }}
                                        />
                                        <p className="px-4 iconMargin  blue featureFonts">
                                            Streamline Social
                                        </p>
                                    </div>
                                    {!isMobile ? <div class="w-100 pt-4"></div> : ""}
                                    <div
                                        class="col-xl-4 col-xs-4 col-sm-4 col-lg-4 col-md-6 d-flex
                  "
                                    >
                                        <img
                                            src={allUrlIcon}
                                            className="float-left "
                                            style={{ objectFit: "fill" }}
                                            width={isMobile ? "20px" : ""}
                                            height={isMobile ? "20px" : "45px"}
                                        />
                                        <p className="px-4 iconMargin  blue featureFonts">
                                            Consolidate Links
                                        </p>
                                    </div>
                                    <div
                                        class="col-xl-4 col-xs-4 col-sm-4 col-lg-4 col-md-6 d-flex
                  "
                                    >
                                        <img
                                            src={dashboardIcon}
                                            className="float-left"
                                            our
                                            community
                                            width={isMobile ? "20px" : ""}
                                            height={isMobile ? "20px" : "45px"}
                                            style={{ objectFit: "fill" }}
                                        />
                                        <p className="px-4 iconMargin  blue featureFonts">
                                            Simple Dashboard
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <p
                                        className={
                                            !isMobile
                                                ? "px-4 mr-1  pt-5 heading2Style blue textHeading"
                                                : "px-4  pt-5 heading2StyleMobile blue textHeading"
                                        }
                                    >
                                        Get started and claim your username now
                                    </p>
                                </div>
                                <div
                                    style={
                                        isMobile ? { marginLeft: "1%" } : { paddingLeft: "8%" }
                                    }
                                >
                                    <div className="row getStartedFields">
                                        <Form className={isMobile ? "w-100" : "w-50"}>
                                            <Form.Row>
                                                <Form.Control
                                                    className={
                                                        isMobile
                                                            ? "userInputStyleMobile w-100"
                                                            : "userInputStyle w-100"
                                                    }
                                                    placeholder="Cryptolinx.io/username"
                                                    name="username"
                                                    value={data.username}
                                                    onChange={onChangeHandle}
                                                    isInvalid={isError}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    isInvalid={isError}
                                                    className="pl-4"
                                                >
                                                    {errorMessage}
                                                </Form.Control.Feedback>
                                            </Form.Row>
                                        </Form>
                                    </div>

                                    <div className="w-100 getStartedFields">
                                        <LinkButton
                                            className="userButtonStyle mt-4  mb-5"
                                            label="Get Started"
                                            onClick={() =>
                                                history.push(`/signup?username=${data.username}`)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 imgStyle">
                                <img src={MobileIcon} className="mobImgStyle mt-5" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div2  ">
                    <div className="row  mt-5 communityStyle">
                        <div className="mt-5 mx-5 my-5 w-100 align-items-center d-flex justify-content-center flex-column margin ">
                            <h1 className="blue headingStylesMobile HeadingSize">Our Community</h1>
                            <p className="text-center">
                                CryptoLinx was built for the cryptosphere. Companies, exchanges
                                and influencers understand the struggle of only being able to
                                add one website url to their social media pages, pinned posts &
                                QR codes (to name a few), yet they have so much content to share
                                with their audiences! Simply create your Cryptolinx.io account
                                and link all your links in one place!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="div3 ">
                    <div
                        className={
                            !isMobile
                                ? "row mx-5 mt-5 d-flex justify-content-evenly"
                                : "row mx-5 mt-5  justify-content-center flex-column"
                        }
                    >
                        <div
                            className={`col-lg-5  col-xl-5 col-xs-12 col-sm-12 col-md-12 ImgOrder mx-5 marginMobileLeft`}
                        >
                            <img src={MobileIcon2} className="imgWidth NegMargin" />
                        </div>
                        <div
                            className="col-lg-5 col-xl-5 margintop col-xs-12 col-sm-12 col-md-12  ParaOrder"
                            data-aos="fade-up"
                            data-aos-duration="500"
                        >
                            <h1
                                className={
                                    isMobile
                                        ? "text-center headingStyles HeadingSize"
                                        : "headingStyles HeadingSize"
                                }
                            >
                                Marketing <span className="pinkColor">Tool</span>
                            </h1>
                            <p className={isMobile ? "text-center desFont" : "desFont"}>
                                A major challenge right now for most is marketing to your base
                                and the crypto community is becoming increasingly frustrated.
                                Social media is a highly effective way to communicate with your
                                audience but with so many channels, it has become very
                                fragmented to find the latest and most relevant info. It’s time
                                to consolidate your content with one simple url to highlight all
                                your important announcements. Share it anywhere...from your
                                social media bio’s to your pinned posts, and essentially
                                wherever you can add a website!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="div4 ">
                    <div
                        className={
                            isMobile
                                ? "flex-column d-flex row mx-5 mt-5 d-flex  marginUnlimitedStyle"
                                : "row mx-5 mt-5 d-flex  marginUnlimitedStyle"
                        }
                    >
                        <div
                            className="col-lg-5 col-xl-5  margintop mr-5 col-xs-12 col-sm-12 col-md-12"
                            data-aos="fade-up"
                            data-aos-duration="500"
                        >
                            <h1
                                className={
                                    !isMobile
                                        ? "headingStyles HeadingSize lineHeight"
                                        : "headingStyles HeadingSize lineHeight text-center"
                                }
                            >
                                Streamline <span className="pinkColor">Social</span>
                            </h1>
                            <p className={isMobile ? "text-center desFont" : "desFont"}>
                                There are many social networks today so enabling your community
                                to view all your accounts via this one page, allows them to
                                easily navigate across all your social profiles. We have linked
                                the most commonly used platforms in the crypto space so you can
                                simply connect them to your CryptoLinx page. Want to track your
                                most popular social links? With our built in analytics, you can
                                view how often they are being clicked!
                            </p>
                        </div>
                        <div className="col-lg-4 col-xl-4 col-md-12  col-sm-12 marginLeft marginMobileRight">
                            <img
                                src={MobileIcon3}
                                width="450px"
                                className="imgWidth marginR NegMargin  "
                            />
                        </div>
                    </div>
                </div>
                <div className="div5 ">
                    <div
                        className={
                            isMobile
                                ? "d-flex flex-column row mx-5 mt-5 marginLeftStyle"
                                : " row mx-5 mt-5 marginLeftStyle d-flex"
                        }
                    >
                        <div
                            className={
                                !isMobile
                                    ? "col-lg-4 col-xl-4 col-sm-12 col-md-12  mx-5 ImgOrder "
                                    : "text-center col-lg-4 col-xl-4 col-sm-12 col-md-12  mx-5 ImgOrder "
                            }
                        >
                            <img src={MobileIcon4} className="imgWidth marginR NegMargin" />
                        </div>
                        <div
                            className={
                                !isMobile
                                    ? "col-lg-6 margintop col-xs-12 col-sm-12 col-md-12 col-xl-6 px-5  ParaOrder marginUnlimitedStyle "
                                    : "text-center col-lg-6 margintop col-xs-12 col-sm-12 col-md-12 col-xl-6 px-2  ParaOrder marginUnlimitedStyleMobile "
                            }
                            data-aos="fade-up"
                            data-aos-duration="500"
                        >
                            <h1 className="headingStyles HeadingSize ">
                                Consolidate <span className="pinkColor">Links</span>
                            </h1>
                            <p className="desFont">
                                These are your call-to-action links! When a visitor lands on
                                your website or social media page, it can be overwhelming. There
                                are often too many options and you only have their attention for
                                a few seconds. Your custom CryptoLinx page allows you to take
                                control and direct your community to specific landing pages.
                                Whether it be entering your competition, latest blog post,
                                promoting merch, downloading your wallet, current job vacancies,
                                or anything you wish to announce, this is your opportunity to
                                grab their attention!
                            </p>
                        </div>
                    </div>
                </div>

                <div className="div6 ">
                    <div
                        className={
                            !isMobile
                                ? "row mx-5 mt-5 d-flex  marginUnlimitedStyle"
                                : "flex-column row mx-5 mt-5 d-flex  marginUnlimitedStyle"
                        }
                    >
                        <div
                            className={
                                !isMobile
                                    ? "col-lg-5 col-xl-5  margintop mr-5 col-xs-12 col-sm-12 col-md-12"
                                    : " col-lg-5 col-xl-5  margintop mr-5 col-xs-12 col-sm-12 col-md-12 text-center"
                            }
                            data-aos="fade-up"
                            data-aos-duration="500"
                        >
                            <h1 className="headingStyles HeadingSize lineHeight">
                                Simple<span className="pinkColor"> Dashboard</span>
                            </h1>
                            <p className="desFont">
                                We all know that this space is moving at lightning speed and how
                                quickly it’s evolving each month. Having the best tools in your
                                arsenal is paramount and crucial to your success. Whether you’re
                                at an early stage or established, CryptoLinx will accelerate and
                                propel your presence in a succinct way. Simply modify your links
                                anytime with our easy to use dashboard and it will update
                                instantly wherever you have shared it. Our analytics also allows
                                you to monitor how well your links are performing!
                            </p>
                        </div>
                        <div className="col-lg-4 col-xl-4 col-md-12  col-sm-12 marginLeft marginMobileRight">
                            <img
                                src={MobileIcon5}
                                width="500px"
                                className="imgWidth marginR NegMargin "
                            />
                        </div>
                    </div>
                </div>
                <div className="w-100 py-4  featuresDiv d-flex justify-content-center align-items-center mt-5">
                    <div className="w-75" data-aos="fade-up" data-aos-duration="500">
                        <p className="d-flex justify-content-center featureHeadingStyle pt-4">
                            Features
                        </p>
                        <p className="d-flex justify-content-center featureDetailDiv">
                            Maximize your online presence with our simple yet effective tool.
                            <br />
                            Below you will find some of the key features included with each
                            plan.
                        </p>
                        <div className="d-flex justify-content-around row ">
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    icon={userIcon}
                                    heading="Guaranteed Username"
                                    details="Since we only service the crypto community, you should be able to claim your preferred url."
                                />
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    className="col-xl-4 col-sm-12 col-md-4 "
                                    icon={call}
                                    heading="Unlimited ‘Call to Action’ Links"
                                    details="Create as many links as you wish to engage visitors through your CryptoLinx page."
                                />
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    className="col-xl-4 col-sm-12 col-md-4 "
                                    icon={social}
                                    heading="Connect Social Networks"
                                    details="Add your social media profiles so your audience can easily navigate across all channels."
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-around row">
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    className="col-lg-12 col-sm-12 col-md-10 w-100"
                                    icon={analytics}
                                    heading="Link Analytics"
                                    details="You can track how well your links are performing over specific periods of time.
                                    "
                                />
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    className="col-lg-12 col-sm-12 col-md-10 w-100"
                                    icon={sociall}
                                    heading="Social Icon"
                                    details="Can be added to website footer and anywhere else you display your social icons."
                                />
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    className="col-lg-12 col-sm-12 col-md-10 w-100"
                                    icon={qr}
                                    heading="QR Code"
                                    details="Ideal for online/offline events, marketing collateral, pinned posts, email campaigns, etc."
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-around row">
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    className="col-lg-4 col-sm-12 col-md-10 w-100"
                                    icon={update}
                                    heading="Update Instantly"
                                    details="Simply add, edit, or remove your custom links in a matter of seconds - anytime, anywhere!"
                                />
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    className="col-lg-12 col-sm-12 col-md-10 w-100"
                                    icon={plans}
                                    heading="Flexible Plans"
                                    details="With our transparent monthly plans, there’s no long-term commitment - cancel anytime."
                                />
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-4">
                                <FeatureComponent
                                    icon={remove}
                                    className="col-lg-12 col-sm-12 col-md-10 w-100"
                                    heading="Professional Page"
                                    details="Your CryptoLinx page will only display your logo, custom links and social icons."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blue  h-100">
                    <div
                        className="mt-5 mb-5  marginRight me-5 ms-5 w-75 pricingBlueContainerHeight align-items-center d-flex pading margin justify-content-center flex-column communityStyle"
                        data-aos="fade-up"
                        data-aos-duration="500"
                    >
                        <h1 className="d-flex justify-content-center pricingHeadingStyle">
                            Pricing
                        </h1>
                        <p className=" d-flex justify-content-center featureDetailDiv">
                            Please view the following plans and subscribe now to lock in our
                            12-month Promotional Pricing!
                            <br />
                            The custom links below are examples to provide you with ideas for
                            your own CryptoLinx page.
                        </p>
                    </div>
                </div>

                <div
                    className="Cardd  d-flex align-items-center justify-content-around w-100"
                    data-aos="fade-up"
                    data-aos-duration="500"
                >
                    <div
                        className={
                            !isMobile
                                ? "card-deck cardContainerStyle marginNeg d_block row"
                                : "card-deck w-100 marginNeg d_block row px-5"
                        }
                    >
                        <div
                            className={
                                isMobile
                                    ? "card cardShadow cardStyle col-xl-4 col-sm-12 col-md-4 mt-5"
                                    : "card cardShadow cardStyle col-xl-4 col-sm-12 col-md-4"
                            }
                        >
                            <div className="card-body w-100  d-flex  flex-column justify-content-center align-items-center">
                                <div className="w-100 d-flex justify-content-center align-items-center pricingIcon">
                                    <img className=" Icon1 float-right " src={influencer} />
                                </div>
                                <p className="card-text float-left subscriptionPackageColor pt-4 mt-3 pricingTypeFont">
                                    Influencer
                                </p>
                                <h1 className="pricingRange">$9/mo</h1>
                                <h1 className="pricingRangeNumber">(USD)</h1>
                            </div>

                            <div className="card-body  ">
                                <h6 className="subscriptionPackageColor exampleHeading">
                                    Link Examples
                                </h6>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor ">
                                        Listen to Podcast
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">Enter my Giveaway</div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Register for Workshop
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Download or Buy Book
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">Latest Blog Post</div>
                                </div>
                            </div>
                            <div className="card-button d-flex align-items-center justify-content-around mb-5 ">
                                <button
                                    className="cardButtonStyle "
                                    onClick={() => history.push("/signup")}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                        <div
                            className={
                                isMobile
                                    ? "card cardShadow CardMtop cardStyle col-xl-4 col-sm-12 col-md-4 mt-5"
                                    : "card cardShadow CardMtop cardStyle col-xl-4 col-sm-12 col-md-4"
                            }
                        >
                            <div className="card-body w-100  d-flex  flex-column  justify-content-center align-items-center">
                                <div className="w-100 d-flex justify-content-center align-items-center pricingIcon">
                                    <img className=" Icon1 float-right " src={companies} />
                                </div>
                                <p className="card-text float-left subscriptionPackageColor pt-4 mt-3 pricingTypeFont">
                                    Company
                                </p>
                                <h1 className="pricingRange">$19/mo</h1>
                                <h1 className="pricingRangeNumber">(USD)</h1>
                            </div>
                            <div className="card-body ">
                                <h6 className="subscriptionPackageColor exampleHeading">
                                    Link Examples
                                </h6>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Download our Wallet
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">View Whitepaper</div>
                                </div>
                                <div className="d-flex my-3">
                                    <img src={tickIcon} height={16} width={14} className="mt-1" />
                                    <div className="ml-1 pricingTextColor">Latest Blog Post</div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Shop Our Merch
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Hiring - Apply Now
                                    </div>
                                </div>
                            </div>
                            <div className="card-button d-flex align-items-center justify-content-around mb-5 ">
                                <button
                                    className="cardButtonStyle "
                                    onClick={() => history.push("/signup")}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>

                        <div
                            className={
                                isMobile
                                    ? "card cardDiv cardShadow CardMtop cardStyle col-xl-4 col-sm-12 col-md-4 mt-5"
                                    : "card cardDiv cardShadow CardMtop cardStyle col-xl-4 col-sm-12 col-md-4"
                            }
                        >
                            <div className="card-body w-100 d-flex  flex-column  justify-content-center align-items-center">
                                <div className="w-100 d-flex justify-content-center align-items-center pricingIcon">
                                    <img className=" Icon1 float-right " src={exchanges} />
                                </div>
                                <p className="card-text float-left subscriptionPackageColor pt-4 mt-3 priceBox pricingTypeFont">
                                    Exchange
                                </p>
                                <h1 className="pricingRange">$29/mo</h1>
                                <h1 className="pricingRangeNumber">(USD)</h1>
                            </div>
                            <div className="card-body ">
                                <h6 className="subscriptionPackageColor exampleHeading">
                                    Link Examples
                                </h6>
                                <div className="d-flex  my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">Download our App</div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Read Crypto Guide
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        View Latest Product
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Watch our Webinars
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img
                                        src={tickIcon}
                                        className="checkSigns"
                                        height={16}
                                        width={14}
                                    />
                                    <div className="ml-1 pricingTextColor">
                                        Latest Partnership
                                    </div>
                                </div>
                            </div>
                            <div className="card-button d-flex align-items-center justify-content-around mb-5 ">
                                <button
                                    className="cardButtonStyle "
                                    onClick={() => history.push("/signup")}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div7 ">
                    <div
                        className="row  mt-5  greyColors faqBox mt-5 "
                        data-aos="fade-up"
                        data-aos-duration="500"
                    >
                        <div
                            className={
                                !isMobile
                                    ? "mt-5 mx-5 my-5 w-100 align-items-center d-flex justify-content-center flex-column"
                                    : "text-center mt-5 mx-5 my-5 w-100 align-items-center d-flex justify-content-center flex-column"
                            }
                        >
                            <h1 className="faqHeading">FAQ's</h1>
                            <p className="d-flex justify-content-center featureDetailDiv">
                                Read through these FAQ’s to find answers to commonly raised
                                questions and if you would
                                {!isMobile ? <br /> : ""}
                                like to get in touch with us, please find our contact details
                                located in our footer.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="div8 mt-5 px-5 questionsList"
                    data-aos="fade-up"
                    data-aos-duration="500"
                >
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpen(!open)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={
                                                !isMobile
                                                    ? "px-4 pb-2 objectFitClass"
                                                    : " objectFitClass      pb-1 pr-2"
                                            }
                                        />
                                    </span>{" "}
                                    <strong>What is CryptoLinx?</strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <div>
                                        <strong>What is CryptoLinx?</strong>
                                    </div>
                                </div>
                            )}

                            <div>
                                {!open ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpen(!open)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpen(!open)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpen(!open)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={open}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                CryptoLinx is an affordable marketing tool built specifically
                                for the Crypto community! We allow you to simply create a custom
                                page to showcase all the important links you would like to share
                                with your audience.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpenOne(!openOne)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>Why should we sign up?</strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>Why should we sign up?</strong>
                                </div>
                            )}

                            <div>
                                {!openOne ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpenOne(!openOne)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpenOne(!openOne)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpenOne(!openOne)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={openOne}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                There are many reasons to join...but here are a few! This is the
                                best way to utilize that one url you have in your social media
                                bio’s. Save time and update your links instantly without having
                                to manually change them across all your marketing channels. Your
                                link will be relevant and look professional, no matter where you
                                post it. Call-to-action links to keep your audience engaged and
                                built in analytics to track what’s performing well. Less
                                complaints from your community as they stay up-to-date with the
                                content you want them to see, all in one place.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpenTwo(!openTwo)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>Where can we share our custom CryptoLinx url?</strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>Where can we share our custom CryptoLinx url?</strong>
                                </div>
                            )}

                            <div>
                                {!openTwo ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpenTwo(!openTwo)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpenTwo(!openTwo)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpenTwo(!openTwo)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={openTwo}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                Anywhere! The most recommended places would be on your social
                                media profiles, where there is usually an option to add a
                                website. You can also share the link and mark it as a ‘pinned
                                post’ which several of the mainstream social networks feature.
                                Outside of your social media presence, you may write blogs, host
                                webinars, run online/offline events etc. so you can also
                                download your own QR code to link to your CryptoLinx page and
                                share across all your marketing collateral.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpenThree(!openThree)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>What username should we use?</strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>What username should we use?</strong>
                                </div>
                            )}

                            <div>
                                {!openThree ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpenThree(!openThree)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpenThree(!openThree)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpenThree(!openThree)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={openThree}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                This is up to you but if you are a cryptocurrency
                                company/exchange, we suggest your company name or ticker. If you
                                are an influencer, you can try your full name, nickname or the
                                username you use on mainstream social networks.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpenFour(!openFour)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>
                                        Do we or our audience need to download anything?
                                    </strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>
                                        Do we or our audience need to download anything?
                                    </strong>
                                </div>
                            )}

                            <div>
                                {!openFour ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpenFour(!openFour)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpenFour(!openFour)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpenFour(!openFour)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={openFour}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                Absolutely not - we have kept it very simple for you and your
                                audience! After signing up, you can login to your dashboard
                                anytime via web or mobile, no software or app to download.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpen5(!open5)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>Can we use any email address?</strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>Can we use any email address?</strong>
                                </div>
                            )}

                            <div>
                                {!open5 ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpen5(!open5)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpen5(!open5)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpen5(!open5)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={open5}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                Please use your work/company email address to avoid any username
                                or verification issues.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpen6(!open6)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>
                                        What is the CryptoLinx icon used for and how can we get it?
                                    </strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>
                                        What is the CryptoLinx icon used for and how can we get it?
                                    </strong>
                                </div>
                            )}

                            <div>
                                {!open6 ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpen6(!open6)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpen6(!open6)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpen6(!open6)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={open6}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                Our icon can be used like any social media icon, which you can
                                add to the footer of your website. After signing up, our team
                                will send you an email with more information.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpen7(!open7)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>
                                        What does the 12-month promotional pricing mean?
                                    </strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>
                                        What does the 12-month promotional pricing mean?
                                    </strong>
                                </div>
                            )}

                            <div>
                                {!open7 ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpen7(!open7)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpen7(!open7)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpen7(!open7)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={open7}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                If you decide to sign up now, you will be able to take advantage
                                of our current promotional pricing plans for a minimum of 12
                                months, even if our plans increase. There is no long term
                                contract (month to month), giving you the flexibility to cancel
                                anytime. All prices are in USD and we use Stripe as our payment
                                gateway, accepting most of the major credit and debit cards.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpen8(!open8)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>
                                        How do we get in touch if we have any questions or need
                                        help?
                                    </strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>
                                        How do we get in touch if we have any questions or need
                                        help?
                                    </strong>
                                </div>
                            )}

                            <div>
                                {!open8 ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpen8(!open8)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpen8(!open8)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpen8(!open8)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={open8}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                You can reach out to us anytime and we will try our best to get
                                back to you within 24-48 hours! Email support@cryptolinx.io or
                                send us a DM.
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                    <p className="blackColor ">
                        <div
                            className="d-flex justify-content-sm-around align-items-center"
                            onClick={() => setOpen9(!open9)}
                        >
                            {!isMobile ? (
                                <div style={{ display: isMobile ? "flex" : "", width: "100%" }}>
                                    <span>
                                        {" "}
                                        <img
                                            src={IndicatorIcon}
                                            className={!isMobile ? "px-4 pb-2" : "pb-1 pr-2"}
                                        />
                                    </span>{" "}
                                    <strong>
                                        Not a cryptocurrency company, exchange or influencer but
                                        interested in signing up or becoming a partner?
                                    </strong>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: isMobile ? "flex" : "",
                                        alignItems: isMobile ? "center" : "",
                                    }}
                                >
                                    <div className="pr-2">
                                        <img src={IndicatorIcon} className="objectFitClass" />
                                    </div>
                                    <strong>
                                        Not a cryptocurrency company, exchange or influencer but
                                        interested in signing up or becoming a partner?
                                    </strong>
                                </div>
                            )}

                            <div>
                                {!open9 ? (
                                    <>
                                        <div
                                            className="col"
                                            onClick={() => setOpen9(!open9)}
                                            style={{ color: "#00FFCE" }}
                                        >
                                            <BsChevronDown
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                        <div className=" d-none">
                                            <div onClick={() => setOpen9(!open9)}>
                                                <BsChevronUp
                                                    style={{
                                                        color: "#246BD6",
                                                        backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                        height: "24px",
                                                        width: "24px",
                                                        padding: "5px",
                                                        borderRadius: "15px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col">
                                        <div onClick={() => setOpen9(!open9)}>
                                            <BsChevronUp
                                                style={{
                                                    color: "#246BD6",
                                                    backgroundColor: "rgba(36, 107, 214, 0.1)",
                                                    height: "24px",
                                                    width: "24px",
                                                    padding: "5px",
                                                    borderRadius: "15px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Collapse in={open9}>
                            <div id="example-collapse-text   " className="IconTextStyle ">
                                We would love to hear from you! For general enquiries, contact
                                us at hello@cryptolinx.io and for partnerships, email us at
                                partnerships@cryptolinx.io and we shall be in touch!
                            </div>
                        </Collapse>
                    </p>
                    <hr />
                </div>
                <div className="div9 w-100  greyColor ">
                    <div className={isMobile ? "mt-5 mx-5" : "mt-5 mx-5 pl-5 pr-5"}>
                        <div className={!isMobile ? "row pl-5 pr-5 pb-3 mainBox d-flex justify-content-between w-100" : "row p-5 ml-1 d-flex justify-content-between w-100"}>
                            <div className="col-lg-5 col-md-5 col-xl-5 col-sm-12 col-xs-12">
                                <div className="d-flex justify-content-between flex-column">
                                    <p
                                        className={
                                            isMobile
                                                ? "footerHeadingStyleMobile"
                                                : "footerHeadingStyle"
                                        }
                                    >
                                        <div>
                                    <img src={footerIcon} height="30px"/>
                                 </div>  
                                    </p>
                                    <p
                                        className={!isMobile ? "footerStyle" : "footerStyleMobile"}
                                    >
                                        All your crypto content in just one link
                                    </p>
                                </div> 
                            </div>
                            {!isMobile ? (
                                <>
                                    <div className=" mt-2 col-lg-5 col-md-5 col-xl-5 col-sm-12 col-xs-12">
                                        <div className="d-flex w-100 flex-column justify-content-around">
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    type="button"
                                                    disabled
                                                    className="btn btn-outline-secondary"
                                                    style={{
                                                        color: "#ffff",
                                                        border: "solid 2px #ffff",
                                                        height: "38px",
                                                    }}
                                                >
                                                    Contact Us
                                                </button>
                                            </div>
                                            <p className="d-flex justify-content-center mt-3">
                                                <a
                                                    style={{ color: "#ffff" }}
                                                    href="mailto:hello@cryptolinx.io"
                                                >
                                                    Enquiries
                                                </a>
                                                <a
                                                    style={{ color: "#ffff", marginLeft: "20px" }}
                                                    href="mailto:partnerships@cryptolinx.io"
                                                >
                                                    Partnerships
                                                </a>
                                                <a
                                                    style={{ color: "#ffff", marginLeft: "20px" }}
                                                    href="mailto:support@cryptolinx.io"
                                                >
                                                    Support
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-2 col-lg-2 col-md-2 col-xl-2 col-sm-12 col-xs-12">
                                        <div className="d-flex flex-column align-items-center">
                                        <button
                                            type="button"
                                            disabled
                                            className="btn btn-outline-secondary"
                                            style={{
                                                color: "#ffff",
                                                border: "solid 2px #ffff",
                                                height: "38px",
                                            }}
                                        >
                                            Follow Us
                                        </button>
                                        <a  target="_blank" href = "https://www.cryptolinx.io/cryptolinx" rel="noreferrer">
                                        <img className="mt-2" src={cryptoIcon} width="40px" height="40px" />
                                        </a>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            <div className="col-lg-8 col-xl-8 col-md-4 col-sm-12 col-xs-12 ">
                                <div className="row footerIconStyle"></div>
                            </div>
                        </div>
                        {isMobile ? (
                            <div className="col-sm-12 col-xs-12 d-flex justify-content-center">
                                <button
                                    type="button"
                                    class="btn btn-outline-secondary"
                                    style={{ color: "#ffff", border: "solid 2px #ffff" }}
                                >
                                    Contact Us
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className={!isMobile ? "row mt-5 mx-5" : "row mt-3 mx-5"}>
                            <div className="imgLengthStyle">
                                {isMobile ? (
                                    <>
                                        <div className="footerText">
                                            <p className="d-flex justify-content-center">
                                                <a
                                                    style={{ color: "#ffff" }}
                                                    href="mailto:hello@cryptolinx.io"
                                                >
                                                    Enquiries
                                                </a>
                                                <a
                                                    style={{ color: "#ffff", marginLeft: "20px" }}
                                                    href="mailto:partnerships@cryptolinx.io"
                                                >
                                                    Partnerships
                                                </a>
                                                <a
                                                    style={{ color: "#ffff", marginLeft: "20px" }}
                                                    href="mailto:support@cryptolinx.io"
                                                >
                                                    Support
                                                </a>
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-xs-12 d-flex flex-column align-items-center">
                                            <button
                                                type="button"
                                                class="btn btn-outline-secondary"
                                                style={{ color: "#ffff", border: "solid 2px #ffff" }}
                                            >
                                                Follow Us
                                            </button>
                                            <a target="_blank" href = "https://www.cryptolinx.io/cryptolinx" rel="noreferrer">
                                        <img className="mt-2" src={cryptoIcon} width="40px" height="40px" />
                                        </a>
                                        </div>
                                        <div>
                                        <p style={{color: "white",textAlign:"center" }}>
                                        <a
                                            style={{ color: "#ffff", marginLeft: "1px",fontSize:"8px" }}
                                            href="/privacypolicy"
                                        >
                                            Privacy Policy |
                                        </a>
                                        <a
                                            style={{ color: "#ffff", marginLeft: "1px",fontSize:"8px" }}
                                            href="/terms"
                                        >
                                            Terms of Service |
                                        </a>
                                        <a
                                            style={{ color: "#ffff", marginLeft: "1px",fontSize:"8px" }}
                                            href="/cookiepolicy"
                                        >
                                            Cookie Policy
                                        </a>
                                    </p>
                                    </div>
                                    </>
                                ) : (
                                    ""
                                )}
                                <hr className="divider" style={{ backgroundColor: "white" }} />
                            </div>
                            {isMobile ? (
                                <div className="col-12 footerStyle">
                                    <p className="footerTextMobile">
                                        Copyright © 2022 Cryptolinx Ltd.
                                    </p>
                                </div>
                            ) : (
                                <div className="col-7 ml-5 footerStyle">
                                    <p className="footerText">
                                        Copyright © 2022 Cryptolinx Ltd.
                                    </p>
                                </div>
                            )}
                            {!isMobile ? (
                                <div className="col-4 footerText">
                                    <p className="d-flex justify-content-end">
                                        <a
                                            style={{ color: "#ffff", marginLeft: "5px" }}
                                            href="/privacypolicy"
                                        >
                                            Privacy Policy |
                                        </a>
                                        <a
                                            style={{ color: "#ffff", marginLeft: "5px" }}
                                            href="/terms"
                                        >
                                            Terms of Service |
                                        </a>
                                        <a
                                            style={{ color: "#ffff", marginLeft: "5px" }}
                                            href="/cookiepolicy"
                                        >
                                            Cookie Policy
                                        </a>
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Home;
