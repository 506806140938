import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { getDataWithAuth, putDataWithAuth } from "../../utilis/api";
import Logo from "../../assets/images/CryptoLogo.svg";
import ProgressBar from "../../assets/images/P2.svg"
import Copyright from "../../assets/images/Copyrights.svg";
import images from "../../assets/images/MobIconImg.svg";
import LinkButton from "../LinkButton";
import { useMediaQuery } from "@material-ui/core"

import "./PersonalInfoStyle.css";

const PersonalInfo = () => {
    let history = useHistory();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ subscription_plan: "influencer", name: "",company_name:"" });
    const [userId, setUserId] = useState();
    const [isLoading, setisLoading] = useState(false);
    const isMobile = useMediaQuery("(max-width: 430px)");
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (
            (name == "subscription_plan" && value == "company") ||
            (name == "subscription_plan" && value == "exchange")
        ) {
            setShow(true);
        } else if (
            (name == "subscription_plan" && value !== "company") ||
            (name == "subscription_plan" && value == "exchanges")
        ) {
            setShow(false);
            setData({ ...data, company_name: "" });
        }
        setData({ ...data, [name]: value });
    };

    const getUserData = async () => {
        const response = await getDataWithAuth("/api/v1/users/me");
        if (!response.success) {
            return;
        }

        setUserId(response.data.data.user.id);
    };
    useEffect(() => {
        getUserData();
    }, []);

    const submitData = async (event) => {
        event.preventDefault();
        console.log("-----", { ...data, persnol_detail: "true" });
        const response = await putDataWithAuth(`/api/v1/users/${userId}`, {
            ...data,
            persnol_detail: "true",
        });
        if (!response.success) {
            enqueueSnackbar("Couldn't update data", {
                variant: "error",
            });
            return;
        }
        history.push("/features");
    };

    return (
        <>
            <div className="row mx-0 my-0">
                <div
                    className={`col-lg-4 col-md-5  col-sm-12  col-xs-12  d-flex justify-content-center ${!isMobile ? "mainBackground":""}`}
                >
                    <div className="row d-flex flex-column  align-items-center justify-content-center ">
                       
                        <div>
                            <img
                                src={Logo}
                                className={!isMobile ? "pb-5 mb-5" : "mt-3"}
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                        { !isMobile ? 
                        <>
                        <div>
                            <img
                                src={images}
                                className="signupStyle "
                                style={{ height: "60vh" }}
                            />
                        </div>
                        <div className="pt-3 mt-5 ">
                        <p className="pt-3" style={{color:"#246bd6"}}>Copyright © 2022 CryptoLinx Ltd. All rights reserved.</p>
                        </div>
                        </> :
                        ""
                        }
                    </div>
                </div>
               
                <div className="col-md-8 col-sm-12 col-xs-12 d-flex justify-content-center scrollClass align-items-center">
                    <div className={`${isMobile ? "w-100" :"w-75"} d-flex justify-content-center align-items-center`}>
                        <div className="w-100">
                        <img src={ProgressBar} className="pb-3" height="60px" width="100%"/>
                            <h3 className="personalInfoHeading">Personal Details</h3>
                            <Form className={`${!isMobile ? "formStyle" : "formStyleMobile"} `}>
                                <Form.Group className="mb-3" controlId="userName">
                                    <Form.Label className="personalInfoLabel ml-2">
                                        Full Name
                                    </Form.Label>
                                    <Form.Control
                                    required
                                        name="name"
                                        type="text"
                                        placeholder="Enter your name"
                                        className={!isMobile ? "inputFieldStyle w-75":"inputFieldStyleMobile w-80"}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <div class="form-group">
                                    <label for="inputState" className="personalInfoLabel ml-2 mt-4">
                                        Who are you?
                                    </label>
                                    <br />
                                    <select
                                    required
                                        id="inputState"
                                        name="subscription_plan"
                                        onChange={handleChange}
                                        className={!isMobile ? "form-control inputFieldStyle w-75":"inputFieldStyleMobile w-80"}
                                    >
                                        <option value="influencer"> Influencer </option>
                                        <option value="company"> Company </option>
                                        <option value="exchange"> Exchange</option>
                                    </select>
                                </div>
                                {show ? (
                                    <Form.Group className="mb-3">
                                        <Form.Label className="personalInfoLabel ml-2">
                                            {data.subscription_plan == "company" ? "Company Name" : "Exchange Name"}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company_name"
                                            className={!isMobile ? "inputFieldStyle w-75":"inputFieldStyleMobile w-80"}
                                            placeholder={data.subscription_plan == "company" ? "Enter company name" : "Enter exchange name"}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                ) : null}

                                <div className={`d-flex justify-content-center align-items-center ${!isMobile ? "w-75" : "w-100"} mt-5`}>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={submitData}
                                        className="submitButton"
                                        disabled={data.name.length<1 || data.subscription_plan.length<1}
                                    >
                                        Add Info
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PersonalInfo;
