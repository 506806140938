import React from "react";
import { getData, postData, getDataWithAuth } from "../../utilis/api";
import profileImg from "../../assets/images/profileImg.png";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import WebIcon from "../../assets/images/WebsiteIcon.png";
import MerchandisIcon from "../../assets/images/MerchandisIcon.png";
import JobIcon from "../../assets/images/JobsIcon.png";
import ContestIcon from "../../assets/images/ContestIcon.png";
import ArticleIcon from "../../assets/images/ArticleIcon.png";
import AppIcon from "../../assets/images/AppIcon.png";
import NewsIcon from "../../assets/images/NewsletterIcon.png";
import fb from "../../assets/images/fb.svg";
import youtube from "../../assets/images/youtube.svg";
import twitter from "../../assets/images/twitter.svg";
import telegram from "../../assets/images/telegram.svg";
import Reddit from "../../assets/images/Rebbit.svg";
import Medium from "../../assets/images/Medium.svg";
import linkdin from "../../assets/images/linkdin.svg";
import instagram from "../../assets/images/instagram.svg";
import Discord from "../../assets/images/Discord.svg";
import WhitePaper from "../../assets/images/Paper.png";
import AppStore from "../../assets/images/AppStore.png";
import AndroidApp from "../../assets/images/androidApp.png";
import Wallet from "../../assets/images/walet.png";
import WorkShop from "../../assets/images/Workshop.png";
import Webninar1 from "../../assets/images/Webinar.png";
import Podcast from "../../assets/images/microphone.png";
import Partnership from "../../assets/images/Patner.png";
import Bulb from "../../assets/images/blub1.png";
import Github from "../../assets/images/github.svg";
import Logo from "../../assets/images/Logo.png";

import "./ShowAllLinksStyle.css";

const ShowAllLinks = (props) => {
  let { profileLink } = useParams();
  const [name, setName] = useState(null);
  const [userProfilePic, setUserProfilePic] = useState();
  const [links, setLinks] = useState([]);
  const [defaultLink, setDefaultLinks] = useState([]);
  const [userId, setUserId] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const userData = async () => {
    const response = await getDataWithAuth("/api/v1/users/me");
    if (!response.success) {
      return;
    }
    setUserProfilePic(response.data.profile_pic);

    // setUserId(response.data.data.user.id);

  };

  const getUserData = async (link) => {
    const response = await getData(`/api/v1/users/${link}`);
    if (!response.success) {

      return;
    }

    setUserProfilePic(response.data.data.profile_pic);
    setName(response.data.data.user.username);
    setLinks(response.data.data.links);
    setDefaultLinks(response.data.data.default_links);
    setUserId(response.data.data.user.id);
  };
  const addView = async () => {
    const response = await postData(`/api/v1/users/${userId}/views`);
    if (!response.success) {
      return;
    }
  };
  useEffect(() => {
    userData();
  }, []);

  useEffect(() => {
    if (!props.dontAddView) {
      addView();
    }
  }, [userId]);

  const addClicks = async (id) => {
    const response = await postData(
      `/api/v1/users/${userId}/links/${id}/clicks`
    );
    if (!response.success) {
      return;
    }
  };

  const addClicksOnSocialLink = async (id) => {
    const response = await postData(
      `/api/v1/users/${userId}/default_links/${id}/default_links_clicks`
    );
    if (!response.success) {
      return;
    }
  };

  useEffect(() => {
    if (props.profileLink) {
      console.log(props.profileLink);
      getUserData(props.profileLink);
    }
  }, [props.change, props.profileLink]);

  useEffect(() => {
    if (profileLink !== undefined) {
      getUserData(profileLink);
    }
  }, []);

  const allSocialIcon = (defaultLink, i) => {
    return defaultLink[i].title == "Medium "
      ? socialIconLink(defaultLink[i].url, Medium, defaultLink[i].id)
      : defaultLink[i].title == "Reddit"
      ? socialIconLink(defaultLink[i].url, Reddit, defaultLink[i].id)
      : defaultLink[i].title == "Facebook"
      ? socialIconLink(defaultLink[i].url, fb, defaultLink[i].id)
      : defaultLink[i].title == "GitHub"
      ? socialIconLink(defaultLink[i].url, Github, defaultLink[i].id)
      : defaultLink[i].title == "Discord"
      ? socialIconLink(defaultLink[i].url, Discord, defaultLink[i].id)
      : defaultLink[i].title == "Instagram Handle"
      ? socialIconLink(defaultLink[i].url, instagram, defaultLink[i].id)
      : defaultLink[i].title == "LinkedIn"
      ? socialIconLink(defaultLink[i].url, linkdin, defaultLink[i].id)
      : defaultLink[i].title == "Youtube"
      ? socialIconLink(defaultLink[i].url, youtube, defaultLink[i].id)
      : defaultLink[i].title == "Twitter Handle"
      ? socialIconLink(defaultLink[i].url, twitter, defaultLink[i].id)
      : defaultLink[i].title == "Telegram"
      ? socialIconLink(defaultLink[i].url, telegram, defaultLink[i].id)
      : "";

    function socialIconLink(link, icon, id) {
      return (
        <div onClick={() => addClicksOnSocialLink(id)}>
          <a
            href={link.includes("http") ? link : "http://" + link}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={icon}
              className={
                props.apply50Width ? "socialIcon" : "socialIconFullWidth"
              }
            />
          </a>
        </div>
      );
    }
  };

  const showSocialIcon = () => {
    var array = [];
    var i = 0;
    if (defaultLink.length > 0) {
      for (i = 0; i < defaultLink.length; i = i + 1) {
        array.push(
          <>
            {defaultLink[i] && defaultLink[i].disable ? (
              <div className="py-2">{allSocialIcon(defaultLink, i)}</div>
            ) : null}
          </>
        );
        // }
      }
    } else {
      <p>nothing to show</p>;
    }
    return array;
  };
  return (
    <>
      <div
        className={`scrollableDiv1 ${
          profileLink !== undefined ? "publicLink" : ""
        }`}
      >
        <div className="marginRtStyle ">
          <div class="text-center">
            <img
              src={userProfilePic ? userProfilePic : profileImg}
              class={
                props.apply50Width
                  ? "rounded imgStylee mt-md-5 mt-2 ml-md-3"
                  : "rounded imgStyleeFullWidth mt-md-5 mt-2 ml-md-3"
              }
              alt="..."
              style={{
                objectFit: "fill",
              }}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center pt-3 pl-md-2">
            <p className="usernameStyle">{name}</p>
          </div>
          <div className="pl-md-2 ">
            {links
              .filter((item) => item.disable)
              .map((item) => (
                <div
                  className="d-flex justify-content-center pb-md-1 pb-2"
                  onClick={() => addClicks(item.id)}
                >
                  <div
                    className={
                      props.apply50Width
                        ? " linksStyless p-2 mb-1"
                        : "linksStyles p-2 mb-1"
                    }
                  >
                    <div
                      className=" row d-flex justify-content-center align-items-center  rowHeight h-100"
                      className={
                        props.apply50Width
                          ? "row d-flex justify-content-center align-items-center  rowHeightMobile"
                          : "row d-flex justify-content-center align-items-center   rowHeight"
                      }
                    >
                      <div
                        className={
                          props.apply50Width
                            ? " width25 d-flex py-md-0 py-2"
                            : " width25 d-flex py-2"
                        }
                      >
                        <div
                          className={
                            props.apply50Width
                              ? "col-2 d-flex justify-content-end"
                              : " col-2 d-flex justify-content-end"
                          }
                        >
                          {item.link_type == "Website" ? (
                            <img src={WebIcon} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Newsletter" ? (
                            <img src={NewsIcon} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Contest" ? (
                            <img src={ContestIcon} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Jobs" ? (
                            <img src={JobIcon} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Merchandise" ? (
                            <img src={MerchandisIcon} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Article/Blog Post" ? (
                            <img src={ArticleIcon} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "App" ? (
                            <img src={AppIcon} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Wallet" ? (
                            <img src={Wallet} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "iOS App" ? (
                            <img src={AppStore} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Android" ? (
                            <img src={AndroidApp} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Workshop" ? (
                            <img src={WorkShop} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Webinar" ? (
                            <img src={Webninar1} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Whitepaper" ? (
                            <img src={WhitePaper} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Podcast" ? (
                            <img src={Podcast} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Partnership" ? (
                            <img src={Partnership} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : item.link_type == "Other" ? (
                            <img src={Bulb} className={props.apply50Width ? "icon ml-2" : "iconMbl ml-2"} />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={
                            props.apply50Width
                              ? "col-10 d-flex  justify-content-center"
                              : " col-10  d-flex  justify-content-center"
                          }
                        >
                          <a
                            href={
                              item.url.includes("http")
                                ? item.url
                                : "http://" + item.url
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="aLink"
                            className="linksFbStyle linkButton"
                          >
                            <div className="link-text">{item.title}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div
            className={
              props.apply50Width
                ? "socialLinksWithWidth"
                : "socialLinksWithoutFullWidth"
            }
          >
            <div
              className={
                props.apply50Width
                  ? "w-100 mt-2 d-flex flex-wrap justify-content-center"
                  : "socialIconDiv pr-2 mt-2 d-flex flex-wrap justify-content-center"
              }
            >
              {showSocialIcon()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowAllLinks;
