import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import "./featureStyle.css"
const FeatureCardComponent = (props) => {
    return (
        <>
            <div className="outerContainer p-4 m-4">
                <div className="iconStyle">
                <img src={props.icon} height="120px" width="120px"/>
                </div>
                <p className="featureHeading pt-4">{props.heading}</p>
                <p className="featureDetail">{props.details}</p>
            </div>
        </>
    )
}
export default FeatureCardComponent;