import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { validatePassword } from "../../utilis/Helper";
import { postDataWithAuth } from "../../utilis/api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Spinner } from "react-bootstrap";
import LinkButton from "../LinkButton";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/CryptoLogo.svg";
import Copyright from "../../assets/images/Copyrights.svg";
import images from "../../assets/images/img.svg";

import image from "../../assets/images/links-img.png";
import "./ResetPasswordStyle.css";
import { setToken } from "../../utilis/LocalStorage";
import { useEffect } from "react";

const ResetPassword = () => {
  let history = useHistory();

  const [data, setData] = useState({ password: "", confirm_password: "" });
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setisLoading] = useState(false);

  const onChangeHandle = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  let token = new URLSearchParams(useLocation().search).get("token");
  if (token) {
    setToken(token);
  }

  const postDataCall = async (event) => {
    event.preventDefault();
    setisLoading(true);

    const response = await postDataWithAuth(
      "/api/v1/users/reset_password",
      data
    );
    if (!response.success) {
      enqueueSnackbar(response.error.message, {
        variant: "error",
      });
      return;
    }
    history.push("./login");
    enqueueSnackbar("Success", {
      variant: "info",
    });

    setisLoading(false);
  };

  return (
    <>
      <div className="componentHeight ">
        <div className="row mx-0 my-0">
          <div
            className="col-lg-4 col-md-5  col-sm-12  col-xs-12  d-flex justify-content-center"
            style={{ backgroundColor: "#246BD61A", height: "100vh" }}
          >
            <div className="row d-flex align-items-center justify-content-center ">
              <div>
                <img
                  src={Logo}
                  className="pt-5"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div>
                <img
                  src={images}
                  className="signupStyle "
                  style={{ height: "50vh" }}
                />
              </div>
              <div>
                <img src={Copyright} className="pb-5" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-7 col-xs-12 col-sm-12 container ">
            <div className=" d-flex flex-column justify-content-center align-items-center scrollClass resetCointanerStyle row mt-5 w-100 ">
              <h6 className="resetHeadingStyle ">Reset Password</h6>
            </div>
            <div>
              <div className="d-flex justify-content-center align-items-center w-100">
                <div className="w-100 ">
                  <Form>
                    <div className="resetCointanerStyle formPadding ">
                      <Form.Group controlId="formBasicPassword ">
                        <Form.Label className="mt-2 labelStyleSignup">
                          Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          required
                          className="w-75 inputFieldSignup"
                          name="password"
                          isInvalid={
                            data.password.length > 1 &&
                            !validatePassword(data.password)
                          }
                          isValid={
                            data.password.length >= 8 &&
                            validatePassword(data.password)
                          }
                          onChange={onChangeHandle}
                        />
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword ">
                        <Form.Label className="mt-2 labelStyleSignup">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm Password"
                          required
                          className="w-75 inputFieldSignup"
                          name="confirm_password"
                          isInvalid={
                            data.confirm_password.length >= 1 &&
                            !validatePassword(data.confirm_password)
                          }
                          isValid={
                            data.confirm_password.length >= 8 &&
                            data.confirm_password == data.password
                          }
                          onChange={onChangeHandle}
                        />
                      </Form.Group>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-5  mr-3">
                      <LinkButton
                        variant="primary"
                        type="submit"
                        onClick={postDataCall}
                        disabled={data.password.length < 1}
                        disabled={isLoading}
                        className="submitButtonStyle  mx-5 pt-3 pb-4"
                        label="Change Password"
                        showSpinner={isLoading}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
