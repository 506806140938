import React from "react";
import images from "../../assets/images/SignUpImage.svg";
import Logo from "../../assets/images/CryptoLogo.svg";
import Copyright from "../../assets/images/Copyrights.svg";
import thankyouImg from "../../assets/images/thanks.svg";
import thankyouProgress from "../../assets/images/P5.svg";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core"

import "./ThankyouStyle.css";
const ThankyouPage = () => {


    const isMobile = useMediaQuery("(max-width: 430px)");
  let history = useHistory();
  const submitData = () => {
    history.push("./links");
  };

  return (
    <>
      <div className="componentHeightSignup">
        <div className="row mx-0 my-0">
          <div
            className={`col-lg-4 col-md-5  col-sm-12  col-xs-12  d-flex justify-content-center ${!isMobile?"mainBackground":""}`}
          >
            {/* <div className="row d-flex align-items-center justify-content-center ">
              <div>
                <img
                  src={Logo}
                  className="pt-5"
                  style={{ objectFit: "contain" }}
                />
              </div>
              { !isMobile ?
              <>
              <div>
                <img
                  src={images}
                  className="signupStyle "
                  style={{ height: "50vh" }}
                />
              </div>
              <div>
                <img src={Copyright} className="pb-5" />
              </div>
              </>:""
              }
            </div> */}
            <div className="row d-flex flex-column scrollClass  align-items-center justify-content-center ">
                        <div>
                            <img
                                src={Logo}
                                className={!isMobile ? "pb-3 mb-5":"pt-3"}
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                        {!isMobile ? 
                        <>
                        <div>
                            <img
                                src={images}
                                className="signupStyle "
                                style={{ height: "60vh" }}
                            />
                        </div>
                        <div className="pt-3 mt-5 ">
                            <img src={Copyright} className="pt-3" />
                        </div>
                        </>
                        :""
                        }
                    </div>
          </div>
          
          <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center scrollClass flex-column  ">
              <div className="d-flex justify-content-center">
          <img src={thankyouProgress} height="60px" width="100%" className="pb-3" style={{objectFit:"contain"}}/>
          </div>
            <img src={thankyouImg} className="thankyouImgStyle" height={!isMobile ? "400px":"200px"} width={!isMobile ? "400px":"200px"} />
            <p className="thankyouHeadingStyle pt-5 pb-5">
              Thanks For Registering
            </p>
            <Button className="submitThankyouButton " onClick={submitData}>
              Continue to Account
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ThankyouPage;
