import React, { useState } from "react";
import { Card, Spinner, Dropdown } from "react-bootstrap";
import { BsCheck, BsX } from "react-icons/bs";
import ThreeDot from "../../assets/images/three-dot.png";
import LinkButton from "../LinkButton";
import "./AddLinksStyle.css";
import { useMediaQuery } from "@material-ui/core";

const AddLinks = (props) => {
  const isMobile = useMediaQuery("(max-width: 430px)");

  return (
    <>
      <Card className="addLinkCard pt-2 animate__slideOutLeft">
        {!isMobile ? (
          <div className="d-flex align-items-center">
            <img src={ThreeDot} className="dotsStyle " />
            <div class="verticalLine"></div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between w-100 px-3">
                <input
                  name="title"
                  placeholder="Title"
                  className="inputFieldStyleLink py-1 px-2 mb-2"
                  value={props.inputList.title}
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="d-flex px-3">
                <input
                  name="url"
                  placeholder="Url"
                  className="inputFieldStyleLink py-1 px-2"
                  value={props.inputList.url}
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="d-flex justify-content-bwtween align-items-center w-100 row my-2 pl-3">
                <div className="col-4">
                  <Dropdown
                    onSelect={(eventKey, event) =>
                      props.handleDropdownChange(event)
                    }
                    title={props.selectedValue}
                  >
                    <Dropdown.Toggle
                      className="dropdownStyle"
                      id="dropdown-basic"
                      name="link_type"
                    >
                      {props.selectedValue}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ height: 250, overflowY: "scroll" }}>
                      <Dropdown.Item name="Website">Website</Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Newsletter"
                      >
                        Newsletter
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Contest"
                      >
                        Contest
                      </Dropdown.Item>

                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Jobs"
                      >
                        Jobs
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Wallet"
                      >
                        Wallet
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="iOS App"
                      >
                        iOS App
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Android"
                      >
                        Android App
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Podcast"
                      >
                        Podcast
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Workshop"
                      >
                        Workshop
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Webinar"
                      >
                        Webinar
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Whitepaper"
                      >
                        Whitepaper Product
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Partnership"
                      >
                        Partnership
                      </Dropdown.Item>

                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Merchandise"
                      >
                        Merchandise
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Article/Blog Post"
                      >
                        Article/Blog Post
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Other"
                      >
                        Other
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex col-8 w-100 justify-content-end">
                  <div className="pr-2" onClick={props.cancelLink}>
                    <BsX
                      style={{
                        fontSize: "28px",
                        marginTop: "15px",
                        color: "#246bd6",
                      }}
                    />
                  </div>
                  <div
                    onClick={props.addLink}
                    className={
                      props.inputList.url.length < 1 ||
                      props.inputList.title.length < 1 ||
                      props.inputList.link_type.length < 1
                        ? "disableDiv pl-2"
                        : "pl-2"
                    }
                  >
                    {props.isAddingLink ? (
                      <Spinner
                        className="spinnerPadding"
                        animation="border"
                        variant="primary"
                      />
                    ) : (
                      <BsCheck
                        style={{
                          fontSize: "28px",
                          marginTop: "15px",
                          color: "#19ddff",
                          fontWeight: "900",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <img src={ThreeDot} className="dotsStyle" />
            <div class="verticalLine"></div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between w-100 px-3">
                <input
                  name="title"
                  placeholder="Title"
                  className="inputFieldStyleLink py-1 px-2 mb-2"
                  value={props.inputList.title}
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="d-flex px-3">
                <input
                  name="url"
                  placeholder="Url"
                  className="inputFieldStyleLink py-1 px-2"
                  value={props.inputList.url}
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="d-flex justify-content-bwtween align-items-center w-100 row my-2 pl-3">
                <div className="col-4">
                  <Dropdown
                    onSelect={(eventKey, event) =>
                      props.handleDropdownChange(event)
                    }
                    title={props.selectedValue}
                  >
                    <Dropdown.Toggle
                      className="dropdownStyle"
                      id="dropdown-basic"
                      name="link_type"
                    >
                      {props.selectedValue}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item name="Website">Website</Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Newsletter"
                      >
                        Newsletter
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Contest"
                      >
                        Contest
                      </Dropdown.Item>

                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Jobs"
                      >
                        Jobs
                      </Dropdown.Item>

                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Merchandise"
                      >
                        Merchandise
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Article"
                      >
                        Article
                      </Dropdown.Item>
                      <Dropdown.Item
                        value={props.inputList.link_type}
                        name="Other"
                      >
                        Other
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex col-8 w-100 justify-content-end">
                  <div className="pr-2" onClick={props.cancelLink}>
                    <BsX
                      style={{
                        fontSize: "28px",
                        marginTop: "15px",
                        color: "#246bd6",
                      }}
                    />
                  </div>
                  <div
                    onClick={props.addLink}
                    className={
                      props.inputList.url.length < 1 ||
                      props.inputList.title.length < 1 ||
                      props.inputList.link_type.length < 1
                        ? "disableDiv pl-2"
                        : "pl-2"
                    }
                  >
                    {props.isAddingLink ? (
                      <Spinner
                        className="spinnerPadding"
                        animation="border"
                        variant="primary"
                      />
                    ) : (
                      <BsCheck
                        style={{
                          fontSize: "28px",
                          marginTop: "15px",
                          color: "#19ddff",
                          fontWeight: "900",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default AddLinks;
